import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <title>Shows de Impro en Berlín | ESIMPRO</title>
      <meta name="description" content="Ven a nuestros shows y eventos de Impro en Berlín. O llamanos e iremos a donde quieras. Español & English." />
    </Helmet>
  );
}

export default Head;
