import { useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Modal, ModalProps } from '../Modal';
import { Button } from '../Button';
import { useServices } from '../../hooks/useServices';
import { LayoutContext } from '../../providers/Layout';

import heart from './heart.svg';

export interface NewsletterModalProps extends ModalProps {
  title?: string;
  onNeverAsk(): void;
  onSubscribe(email: string): void;
}

const NewsletterModalContainer = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: calc(13.125vw + 248px); /* 1920: 500, 320: 290 */
  height: calc(13.125vw + 248px); /* 1920: 500, 320: 290 */
  background-color: ${({ theme }) => theme.palette.background};
  transform: translateX(-50%);
  border-radius: 50%;
  box-shadow: 0px 4px 95px -38px #FD5D27;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
`;

const Heart = styled.img`
  width: min(77px, calc(10.625vw + -4px)); /* 1920: 200, 320: 30 */
`;

const Text = styled.p`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.375vw + 8.8px); /* 1920: 16, 320: 10 */
  line-height: 1.25em;
  text-align: center;
  margin: 1.75em 0 3em 0;
  width: 71%;
`;

const Title = styled.span`
  color: #FD5D27;
`;

const Input = styled.input`
  background-color: white;
  padding: 0.5em;
  border-radius: 8px;
  color: black;
  width: 75%;
  font-family: ${({ theme }) => theme.font.quicksand};
  font-weight: 700;
  font-size: calc(0.375vw + 10.8px); /* 1920: 18, 320: 12 */
  line-height: 1.25em;
  margin-bottom: calc(0.5vw + 22.4px); /* 1920: 32, 320: 24 */
`;

const SubscribeButton = styled(Button)`
  font-size: 14px;
  margin-bottom: calc(0.375vw + 8.8px); /* 1920: 16, 320: 10 */
`;

const RejectButtons = styled.div`
`;

const RejectButton = styled(Button)`
  background-color: transparent;
  font-size: calc(0.25vw + 5.2px); /* 1920: 10, 320: 6 */
  border: ${({ theme }) => theme.palette.orange} solid 1px;
  margin: 0 0.5em;
`;

const isValidEmail = (email: string) =>
   email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);

function NewsletterModal({
  open,
  onClose,
  onNeverAsk,
  onSubscribe,
  title,
}: NewsletterModalProps) {
  const { modalPortal } = useContext(LayoutContext);
  const [email, setEmail] = useState('');

  return (
    <Modal open={open} onClose={onClose}>
      <NewsletterModalContainer onClick={event => event.stopPropagation()}>
        <Heart src={heart} alt='Heart' />
        <Text>
          Suscríbete a nuestra newsletter para que te avisemos al programar {title ? <Title>{title}</Title> : 'este evento'}, ¡y muchas más cosas!
        </Text>
        <Input
          type='text'
          placeholder='email'
          value={email}
          onClick={event => event.stopPropagation()}
          onChange={event => setEmail(event.target.value)}
          onKeyDown={e => { if (e.key === 'Enter') onSubscribe(email); }}
        />
        <SubscribeButton
          onClick={event => {
            event.stopPropagation();
            onSubscribe(email);
          }}
          disabled={!isValidEmail(email)}
        >
          Suscribirme
        </SubscribeButton>
        <RejectButtons>
          <RejectButton
            onClick={event => {
              event.stopPropagation();
              onNeverAsk();
            }}
          >
            Nunca
          </RejectButton>
          <RejectButton
            onClick={event => {
              event.stopPropagation();
              onClose();
            }}
          >
            Ahora no
          </RejectButton>
        </RejectButtons>
      </NewsletterModalContainer>
    </Modal>
  );
}

export { NewsletterModal };
