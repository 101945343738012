import { FC, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { Event, EventProps } from './Event';
import { Arrow } from '../Arrow';
import { EmptyList } from './EmptyList';
import { useLayout } from '../../hooks';

// FIX ME Come ooooonnn....
const ScrollableContainerContainer = styled.div`
  position: relative;
  width: 100vw;
`;

const UpcomingEventsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: calc(2.5vw + 32px);
  margin-bottom: calc(5.625vw + 42px); /* 1920: 150, 320: 60 */
  transition: transform 0.3s;

  &::after {
    content: "     ";
    white-space: pre;
  }
`;

const ArrowStyled = styled(Arrow)<{ visible: boolean }>`
  color: ${({ theme }) => theme.palette.lightBlue};
  pointer-events: ${({ visible }) => visible ? 'auto' : 'none'};
  opacity: ${({ visible }) => visible ? 0.9 : 0};
  transition: opacity 0.3s;
  cursor: pointer;
  width: calc(1.5625vw + 45px); /* 1920: 75, 320: 50 */
`;

const ArrowLeft = styled(ArrowStyled)`
  transform: rotate(180deg);
`;

const ArrowRight = styled(ArrowStyled)`
`;

const ArrowContainer = styled.div`
  position: absolute;
  width: 100vw;
  height: 40px;
  left: 0;
  right: 0;
  bottom: calc(3.90625vw + 7.5px); /* 1600: 70, 320: 20 */
  display: flex;
  justify-content: space-between;
  padding: 0 1em;
`;

function ScrollableContainer({
  events
}: {
  events: Array<EventProps>;
}) {
  const layout = useLayout();
  const containerRef = useRef<HTMLDivElement>(null);
  const firstChild = useRef<HTMLDivElement>(null);
  const lastChild = useRef<HTMLDivElement>(null);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(false);

  const translate = useRef(0);
  function setPosition(x: number, transition = true) {
    if (containerRef.current) {
      if (!transition) {
        const transition = containerRef.current.style.transition;
        containerRef.current.style.transition = 'none';
        containerRef.current.style.transform = `translateX(${x}px)`;
        containerRef.current.offsetHeight; // Reflow
        containerRef.current.style.transition = transition;
      } else {
        containerRef.current.style.transform = `translateX(${x}px)`;
      }
      translate.current = x;
      handleArrowVisibility();
    }
  }
  function move(delta: number, transition = true) {
    setPosition(translate.current + delta, transition);
  }

  const handleArrowVisibility = useCallback(() => {
    const { current: container } = containerRef;
    const { current: first } = firstChild;
    const { current: last } = lastChild;

    if (first) {
      const left = first.offsetLeft;
      const overflowLeft = left < 0;
      setArrowLeft(left + translate.current < 0);
    }
    if (last) {
      const right = last.offsetLeft + last.offsetWidth - layout.width;
      setArrowRight(right + translate.current > 0);
    }
  }, [layout]);

  const handleInitialPosition = useCallback(() => {
    const { current: container } = containerRef;
    const { current: first } = firstChild;
    const { current: last } = lastChild;

    // Clamp to left side of the screen if overflowing
    const padding = 32;
    if (first) {
      const left = first.offsetLeft - padding;
      const overflowLeft = left < 0;
      if (overflowLeft) {
        setPosition(left * -1);
      } else {
        setPosition(0);
      }
    }
  }, [layout]);

  useLayoutEffect(() => {
    setTimeout(handleArrowVisibility, 100);
  }, [handleArrowVisibility]);

  useLayoutEffect(() => {
    setTimeout(handleInitialPosition, 50);
  }, [handleInitialPosition]);

  const touches = useMemo(() => new Map<number, { clientX: number }>(), []);

  if (events.length < 1) {
    return <EmptyList />;
  }

  return (
    <ScrollableContainerContainer>
      <UpcomingEventsContainer
        ref={containerRef}
        onTouchStart={event => {
          for (let i = 0; i < event.changedTouches.length; ++i) {
            const eventTouch = event.changedTouches.item(i);
            touches.set(eventTouch.identifier, {
              clientX: eventTouch.clientX,
            });
          }
        }}
        onTouchMove={event => {
          for (let i = 0; i < event.changedTouches.length; ++i) {
            const eventTouch = event.changedTouches.item(i);
            const touch = touches.get(eventTouch.identifier);
            if (touch) {
              const delta = eventTouch.clientX - touch.clientX;
              touches.set(eventTouch.identifier, {
                clientX: eventTouch.clientX,
              });
              move(delta, false);
            }
          }
        }}
        onTouchEnd={event => {
          for (let i = 0; i < event.changedTouches.length; ++i) {
            const eventTouch = event.changedTouches.item(i);
            touches.delete(eventTouch.identifier);
          }
        }}
        onTouchCancel={event => {
          for (let i = 0; i < event.changedTouches.length; ++i) {
            const eventTouch = event.changedTouches.item(i);
            touches.delete(eventTouch.identifier);
          }
        }}
      >
        {events.map((event, i, events) => {
          if (i === 0) {
            return <Event key={event.id} {...event} ref={firstChild} />;
          }
          if (i === events.length - 1) {
            return <Event key={event.id} {...event} ref={lastChild} />;
          }
          return <Event key={event.id} {...event} />;
        })}
      </UpcomingEventsContainer>
      <ArrowContainer>
        <ArrowLeft visible={arrowLeft} onClick={() => move(270) }/>
        <ArrowRight visible={arrowRight} onClick={() => move(-270) }/>
      </ArrowContainer>
    </ScrollableContainerContainer>
  );
}

export { ScrollableContainer };
