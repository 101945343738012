import { useServices } from "../../../hooks";
import { useMemo } from "react";
import styled from "styled-components";
import { FacilitatorCard } from "./FacilitatorCard";

const SliderContainer = styled.div`
  display: grid;
  height: calc(((75vw - (2vw * 2)) / 3) * 1.3 + 100px);
  width: 100%;
  overflow-y: visible;

  @media (max-width: 576px) {
    max-height:max-content ;
    height: max-content;
    margin-bottom: 100px;
  }

  @media (min-width: 577px) and (max-width: 1199px) {
    height: max-content;
    margin-bottom: 40px;
  }
`;

const Container = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  height: 100%;
  gap: 5%;
  justify-items: center;
  overflow: visible;
  padding: 40px 0px 40px 0px;

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 25rem);
    grid-row-gap: 3.5%;
    padding: 0px;
  }
  
   @media (min-width: 577px) and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 25rem);
    grid-column-gap: 3.5%;
    grid-row-gap: 3.5%;
    padding: 0px;
  }
`;

const ListItem = styled.li`
  max-height: 100%;
  width: 100%;
`;


export const FacilitatorCardsContainer = () => {
  const { data } = useServices();

  const cards = useMemo(() => {
    return data.facilitators;
  }, [data]);

  return (
    <SliderContainer>
      <Container>
        {cards?.map((card) => {
          return (
            <ListItem key={card.id}>
              <FacilitatorCard
                name={card.name}
                shortDescription={card.short_description}
                longDescription={card.long_description}
                imageURL={card.image.url}
                imageAlt={card.image.alt}
              />
            </ListItem>
          );
        })}
      </Container>
    </SliderContainer>
  );
};
