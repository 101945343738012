import { FC, ReactNode, useLayoutEffect, useRef, useState, forwardRef } from 'react';
import styled from 'styled-components';
import { clamp } from './utils';

interface Padding {
  horizontal: number;
  vertical: number;
}

interface CollapsibleButtonProps {
  open: number; // Open state, range [0-1]
  borderColor: string;
  ref: any;
  onClick(): void;
  fillColor?: string;
  extraContent?: ReactNode;
  padding?: Padding;
}

const CollapsibleButtonContainer = styled.div`
  display: inline-block;
  position: relative;
`;

interface Size {
  width: number;
  height: number;
}

const Box = styled.svg<{ size: Size, padding: Padding }>`
  position: absolute;
  cursor: pointer;
  display: ${({ size }) => size.width > 0 ? 'initial' : 'none'};
  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
  transform: ${({ padding }) => `translate(-${padding.horizontal}px, -${padding.vertical}px)`};
`;

interface ContentFillProps {
  $scale: number;
}
const ContentFill = styled.g.attrs(({ $scale }: ContentFillProps) => ({
  style: {
    transform: `scaleX(${$scale})`,
  },
}))<ContentFillProps>`
  transform-origin: center;
  transition: transform 50ms;
  will-change: transform;
`;

interface EndProps {
  $offset: number;
}
const End = styled.path.attrs(({ $offset }: EndProps) => ({
  style: {
    transform: `translateX(${$offset}px)`,
  },
}))<EndProps>`
  transition: transform 50ms;
  will-change: transform;
`;

interface ContentProps {
  $opacity: number;
}
const Content = styled.div.attrs(({ $opacity }: ContentProps) => ({
  style: {
    opacity: $opacity
  },
}))<ContentProps>`
  pointer-events: none;
  transition: opacity 50ms;
  user-select: none;
  will-change: opacity;
`;

const ExtraContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const CollapsibleButton = forwardRef<HTMLDivElement, CollapsibleButtonProps>(({
  padding = {
    horizontal: 36,
    vertical: 16,
  },
  onClick,
  ...props
}, ref) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<{ width: number; height: number; }>({
    width: 80 + padding.horizontal * 2,
    height: 20 + padding.vertical * 2,
  });

  useLayoutEffect(() => {
    if (contentRef.current) {
      const rect = contentRef.current.getBoundingClientRect();
      setSize({
        width: rect.width + padding.horizontal * 2,
        height: rect.height + padding.vertical * 2,
      });
    }
  }, []);

  const radius = size.height * 0.5;
  const stroke = 1;
  const halfStroke = 0.5;
  const leftEndPath = `M${radius} ${halfStroke}A${radius - halfStroke} ${radius - halfStroke} 0 0 0 ${radius} ${radius * 2 - halfStroke}`;
  const rightEndPath = `M${size.width - radius} ${halfStroke}A${radius - halfStroke} ${radius - halfStroke} 0 0 1 ${size.width - radius} ${radius * 2 - halfStroke}`;

  const lineStart = radius - halfStroke;
  const lineEnd = size.width - radius + halfStroke;
  const lineLength = lineEnd - lineStart;

  const endOffset = (lineLength - halfStroke) * (1 - props.open) * 0.5;
  const endFill = props.fillColor || 'none';

  return (
    <CollapsibleButtonContainer {...props} ref={ref}>
      <Box version="1.1" xmlns="http://www.w3.org/2000/svg" onClick={onClick} size={size} padding={padding}>
        {props.open > 0
          ? (
            <>
              <ContentFill stroke={props.borderColor} strokeWidth="2" $scale={props.open}>
                {props.fillColor ? (
                    <rect x={lineStart} y="0" width={lineEnd - lineStart + stroke} height="100%" fill={props.fillColor} />
                ) : (
                    <>
                      <line x1={lineStart} x2={lineEnd} y1="0" y2="0" />
                      <line x1={lineStart} x2={lineEnd} y1="100%" y2="100%" />
                    </>
                )}
              </ContentFill>
              <End d={leftEndPath} stroke={props.borderColor} strokeWidth={stroke} fill={endFill} $offset={endOffset} />
              <End d={rightEndPath} stroke={props.borderColor} strokeWidth={stroke} fill={endFill} $offset={-endOffset} />
            </>
          ) : props.fillColor
            ? (
              <circle cx="50%" cy="50%" r={lineStart} fill={props.fillColor} />
            ) : (
              <circle cx="50%" cy="50%" r={lineStart} stroke={props.borderColor} strokeWidth={stroke} fill="none" />
            )
        }
      </Box>
      <Content ref={contentRef} $opacity={clamp(0, 1, props.open * 2 - 1)}>
        {props.children}
      </Content>
      <ExtraContent>
        {props.extraContent || null}
      </ExtraContent>
    </CollapsibleButtonContainer>
  );
});

export {
  CollapsibleButton,
  Content,
};
export type { CollapsibleButtonProps };
