import { FC } from "react";

interface TestimonialStarSvgProps {
  half?: boolean;
}

export const TestimonialStarSvg: FC<TestimonialStarSvgProps> = ({
  half = false,
}) => {
  return (
    <svg
      width={half ? "13" : "26"}
      height="25"
      viewBox={half ? "0 0 13 25" : "0 0 26 25"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8348 0.82666L15.7164 9.86937H25.0414L17.4973 15.4581L20.3789 24.5008L12.8348 18.9121L5.29069 24.5008L8.17227 15.4581L0.628179 9.86937H9.95319L12.8348 0.82666Z"
        fill="currentColor"
      />
    </svg>
  );
};
