import styled from "styled-components";
import { CookieManager } from "./CookieManager";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";
import { zIndexes } from "../styles/variables";

interface FooterProps {
  colab?: boolean;
}

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndexes["header"]};
  padding: 0 7%;
  padding-bottom: calc(1.0625vw + 11.6px); /* 1920: 32, 320: 15 */
  display: flex;
  justify-content: space-between;

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  color: white;
`;

const MailTo = styled.a`
  color: inherit;
`;

const LinksContainer = styled.div`
  @media (max-width: 630px) {
    margin-top: 1em;
  }
`;

const FacebookLink = styled.a`
  margin-right: 1em;
  color: white;
`;

const InstagramLink = styled.a`
  margin-right: 0.85em;
  color: white;
`;

const CookieManagerStyled = styled(CookieManager)`
  width: 26px;
  height: 26px;
  vertical-align: top;
`;

function Footer({ colab = false }: FooterProps) {
  return (
    <FooterContainer>
      <Text>
        ESIMPRO 2023 | Development:&nbsp;
        <MailTo href="mailto:eloi@emgware.com?subject=ESIMPRO website">
          EmgWare
        </MailTo>
        &nbsp;/ Design:&nbsp;louromera
        {colab ? (
          <>
            &nbsp;/ Colab:&nbsp;
            <MailTo href="mailto:dhadesigners@gmail.com?subject=ESIMPRO website">
              DhaDesigners
            </MailTo>
          </>
        ) : null}
      </Text>
      <LinksContainer>
        <InstagramLink
          href="https://instagram.com/berlin_es_impro"
          target="_blank"
        >
          <Instagram />
        </InstagramLink>
        <FacebookLink href="https://www.facebook.com/ESIMPRO/" target="_blank">
          <Facebook />
        </FacebookLink>
        <CookieManagerStyled />
      </LinksContainer>
    </FooterContainer>
  );
}

export { Footer };
