import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ShowFormatTypes } from './types';
import { WishlistTypes } from '../../types';
import { Heart } from '../../components/Heart';
import { NewsletterModal } from '../../components/NewsletterModal';
import { useWishlist } from '../../hooks';

import flagEn from './assets/flag_en.png';

interface ShowProps {
  id: ShowFormatTypes;
  title: string;
  description: string;
  language?: 'en';
  image: {
    src: string;
    alt: string;
  };
}

const apiUrl = process.env.REACT_APP_API_URL;

const ShowContainer = styled.div`
  color: white;
  margin: 0 10px 55px 10px;

  @media (max-width: 526px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const PictureContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-bottom: 24px;
  overflow: hidden;
`;

const Picture = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 55%;
`;

const PictureImg = styled.img`
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
`;


const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 6px;
`;

const Description = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
`;

const HeartStyled = styled(Heart)`
  position: absolute;
  bottom: 8px;
  right: 12px;
`;

const Flag = styled.img`
  position: absolute;
  top: 12px;
  right: 12px;
`;

function Show({
  id,
  title,
  description,
  language,
  image,
}: ShowProps) {
  const { heartProps, newsletterModalProps } = useWishlist(WishlistTypes.ShowFormat, id);

  return (
    <ShowContainer>
      <PictureContainer>
        <Picture>
          <PictureImg src={image.src} alt={image.src} />
          <HeartStyled {...heartProps} />
          {language === 'en' && (
            <Flag src={flagEn} alt='Bandera de Inglaterra' />
          )}
        </Picture>
      </PictureContainer>
      <Title>
        {title}
      </Title>
      <Description>
        {description}
      </Description>
      <NewsletterModal {...newsletterModalProps} title={title} />
    </ShowContainer>
  );
}

export default Show;
