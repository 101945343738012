import { Context, Environment } from './types';
import { Providers } from './providers';
import { Head } from './Head';
import { Routes } from './Routes';

interface AppProps {
  environment: Environment;
  context?: Context;
}

const App = ({ environment, context }: AppProps) => (
  <Providers environment={environment} context={context}>
    <Head />
    <Routes />
  </Providers>
);

export { App };
