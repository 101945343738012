const routes = [
  '/home.html',
  '/shows.html',
  '/school.html',
  '/about.html',
  '/teams.html',
  '/contact.html',
  '/live.html',
];

module.exports = { routes };
