import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { FingerprintLoader } from './base';

class BrowserFingerprint extends FingerprintLoader {
  load(): void {
    const fingerprintKey = 'esimpro.fingerprint';
    const storedFingerprint = localStorage.getItem(fingerprintKey);
    if (storedFingerprint && /^[0-9a-f]{32}$/i.test(storedFingerprint)) {
      this.onLoad(storedFingerprint);
    } else {
      this.loading = true;

      FingerprintJS.load()
        .then(agent => agent.get())
        .then(({ visitorId }) => {
          localStorage.setItem(fingerprintKey, visitorId);
          if (this.loading) {
            this.onLoad(visitorId);
          }
        })
    }
  }
}

export { BrowserFingerprint };
