export const UpArrowSvg = () => {
  return (
    <svg
      width="26"
      height="15"
      viewBox="0 0 26 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 14.5L13.9177 1.39102C13.5217 0.922676 12.8014 0.917799 12.3991 1.38074L1 14.5"
        stroke="currentColor"
      />
    </svg>
  );
};
