import styled from "styled-components";
import { useMemo } from "react";

import { useServices } from "../../hooks";
import { IntroMedia } from "./components/IntroMedia";
import { SeparatorSvg } from "./assets/Separator.svg";
import { PageTitle } from "./components/PageTitle";
import { Hero } from "./components/Hero";
import { colors, zIndexes } from "../../styles/variables";
import { Section } from "./components/Section";
import { TrainingCardsContainer } from "./components/TrainingCardsContainer";
import { CommonButton } from "./components/CommonButton";
import { EventCardsContainer } from "./components/EventCardsContainer";
import { FacilitatorCardsContainer } from "./components/FacilitatorCardsContainer";
import { TestimonyCardsContainer } from "./components/TestimonyCardsContainer";
import { ContactForm } from "./components/ContactForm";
import { typographies } from "../../styles/typographies";
import { Footer } from "../../components/Footer";

const Container = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 80px;

  @media (max-width: 992px) {
    gap: calc(2.5vw + 40px); /* 992: 80, 320: 40 */
  }
`;

const VideoContainer = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: ${zIndexes["content"]};
`;

const TrainingsContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

const ContactSection = styled.section`
  align-items: center;
  color: ${colors.mainText};
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const ContactTitle = styled.h2`
  ${typographies.pageTitle};
`;

const CustomFooter = styled.footer`
  height: 160px;
  position: relative;
`;

export function EnterprisePage() {
  const { data } = useServices();

  const trainingSection = useMemo(() => {
    return data.enterprise_page?.trainings[0];
  }, [data]);

  const eventsSection = useMemo(() => {
    return data.enterprise_page?.events[0];
  }, [data]);

  const facilitatorsSection = useMemo(() => {
    return data.enterprise_page?.facilitators[0];
  }, [data]);

  const testimonialsSection = useMemo(() => {
    return data.enterprise_page?.testimonials[0];
  }, [data]);

  const contactSection = useMemo(() => {
    return data.enterprise_page?.contact[0];
  }, [data]);

  const mediaType = useMemo(() => {
    return data.enterprise_page?.video.kind;
  }, [data]);

  return (
    <>
      <Container>
        <VideoContainer>
          <IntroMedia
            src={data.enterprise_page?.video.url || ""}
            kind={mediaType}
          />
          <PageTitle title={data.enterprise_page?.page_title || ""} />
        </VideoContainer>
        <Hero />
        <Section
          title={trainingSection?.title || ""}
          subtitle={trainingSection?.subtitle}
        >
          <TrainingsContentWrapper>
            <TrainingCardsContainer />
            <a href="#contact">
              <CommonButton>{trainingSection?.button}</CommonButton>
            </a>
          </TrainingsContentWrapper>
        </Section>
        <SeparatorSvg />
        <Section title={eventsSection?.title || ""}>
          <EventCardsContainer />
          <a href="#contact">
            <CommonButton color="secondary">
              {eventsSection?.button}
            </CommonButton>
          </a>
        </Section>
        <SeparatorSvg />
        <Section
          title={facilitatorsSection?.title || ""}
          subtitle={facilitatorsSection?.subtitle || ""}
        >
          <FacilitatorCardsContainer />
        </Section>
        <SeparatorSvg />{" "}
        <Section
          title={testimonialsSection?.title || ""}
          subtitle={testimonialsSection?.subtitle || ""}
        >
          <TestimonyCardsContainer />
        </Section>
        <ContactSection id="contact">
          <ContactTitle>{contactSection?.title}</ContactTitle>
          <ContactForm />
        </ContactSection>
      </Container>
      <CustomFooter>
        <Footer colab={true} />
      </CustomFooter>
    </>
  );
}
