import { createContext, useEffect, useMemo, useState } from "react";
import { Environment } from "../types";
import { getDateFingerprint, useFingerprint } from "../controllers/fingerprint";
import { PrismicManager } from "../static/prismic";

import type { FC } from "react";
import type { Context, Context as RenderContext, Storage } from "../types";

interface ServicesProps {
  context?: RenderContext;
  environment: Environment;
}

interface ServicesContextType {
  fingerprint: string;
  storage: Storage;
  data: RenderContext["data"];
}

const defaultContext = {
  fingerprint: getDateFingerprint("defaultContext"),
  storage: {
    getItem: () => null,
    setItem: () => {},
    removeItem: () => {},
  },
  data: {
    teamupEvents: [],
    shows: [],
    event_service: [],
    facilitators: [],
    training: [],
    testimony: [],
  },
};

const ServicesContext = createContext<ServicesContextType>(defaultContext);

const Services: FC<ServicesProps> = ({
  context: renderContext,
  environment,
  children,
}) => {
  const [finalDefaultContext, setFinalDefaultContext] =
    useState(defaultContext);

  useEffect(() => {
    if (environment === Environment.Browser) {
      const prismicManager = new PrismicManager("en-us");
      prismicManager.getData().then(() => {
        setFinalDefaultContext((currentContext) => {
          return {
            ...currentContext,
            data: { ...currentContext.data, ...prismicManager.data },
          };
        });
      });
    }
  }, [environment]);

  const fingerprint = useFingerprint(environment);

  const storage = useMemo(
    () =>
      environment === Environment.Static
        ? {
            getItem: () => null,
            setItem: () => {},
            removeItem: () => {},
          }
        : window.localStorage,
    [environment, renderContext]
  );

  const contextData = useMemo(
    () =>
      environment === Environment.Static
        ? renderContext?.data
        : window.emgware?.data,
    [environment, renderContext]
  );

  const context = useMemo(
    () => ({
      fingerprint,
      storage,
      data: contextData || finalDefaultContext.data,
    }),
    [contextData, fingerprint, storage, finalDefaultContext]
  );

  return (
    <ServicesContext.Provider value={context}>
      {children}
    </ServicesContext.Provider>
  );
};

export { Services, ServicesContext };
export type { ServicesContextType, Storage };
