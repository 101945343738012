const viewerTypes = {
  Audience: 'audience',
  Streamer: 'streamer',
  MediaProvider: 'mediaProvider',
  Staff: 'staff',
};

const messageTypes = {
  Hello: 'hello',
  Ping: 'ping',
  Pong: 'pong',
  MediaSource: 'mediaSource',
  StreamSource: 'streamSource',
  SuggestionRequests: 'suggestionRequests',
  Suggestion: 'suggestion',
  Suggestions: 'suggestions',
  Scene: 'scene',
  Player1Vote: 'player1Vote',
  Player2Vote: 'player2Vote',
  Player3Vote: 'player3Vote',
  Player4Vote: 'player4Vote',
  DonateVisible: 'donateVisible',
  VideoPopup: 'videoPopup',
  AudienceVoting: 'audienceVoting',
};

function getLiveshowApi({ url, client, viewerType }) {
  let socket = { readyState: 3 };

  let suggestions = [];
  let clearSuggestions = () => { suggestions = []; };

  let watchdog = null;
  let ping = false;

  function init(listener) {
    clearSuggestions = () => {
      suggestions = [];
      listener(messageTypes.Suggestions, suggestions);
    };

    function openConnection() {
      if (!client) return;

      socket = new client(url);

      socket.addEventListener('open', function () {
        ping = false;
        sendMessage(messageTypes.Hello, viewerType);
      });

      socket.addEventListener('message', function (event) {
        const { type, data } = JSON.parse(event.data || '{}');
        switch (type) {
          case messageTypes.Hello: {
            if (data.mediaSources) {
              Object.entries(data.mediaSources).forEach(([mediaType, source]) => listener(mediaType, source));
            }
            if (data.suggestions) {
              suggestions = data.suggestions;
              listener(messageTypes.Suggestions, suggestions);
            }
            if (data.streamSource) {
              listener(messageTypes.StreamSource, data.streamSource);
            }
            if (data.suggestionRequests) {
              listener(messageTypes.SuggestionRequests, data.suggestionRequests);
            }
            if (data.scene) {
              listener(messageTypes.Scene, data.scene);
            }
            if (data.donateVisible) {
              listener(messageTypes.DonateVisible, data.donateVisible);
            }
            if (data.videoPopup) {
              listener(messageTypes.VideoPopup, data.videoPopup);
            }
            if (data.audienceVoting) {
              listener(messageTypes.AudienceVoting, data.audienceVoting);
            }
            break;
          }
          case messageTypes.Suggestion: {
            suggestions = [...suggestions, data];
            listener(messageTypes.Suggestions, suggestions);
            break;
          }
          case messageTypes.Ping: {
            sendMessage(messageTypes.Pong);
            break;
          }
          case messageTypes.Pong: {
            ping = false;
            break;
          }
          default:
            listener(type, data);
            break;
        }
      });

      clearInterval(watchdog);
      watchdog = setInterval(() => {
        sendMessage(messageTypes.Ping);
        ping = true;
        setTimeout(() => {
          if (ping) {
            openConnection();
          }
        }, 2000);
      }, 10000);
    }

    if (url) {
      openConnection();
    }
  }

  function sendMessage(type, data) {
    if (socket.readyState === 1) {
      socket.send(JSON.stringify({
        type,
        ...data ? { data } : {},
      }));
    }
  }

  function close() {
    clearInterval(watchdog);
    if (socket.readyState < 2) {
      socket.close();
    }
  }

  const liveshowApi = {
    init,
    close,
    sendMessage,
    clearSuggestions: () => clearSuggestions(),
  };

  return liveshowApi;
}

export {
  getLiveshowApi,
  messageTypes,
  viewerTypes,
};
