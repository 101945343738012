import { FC, useContext } from 'react';
import styled from 'styled-components';
import { HeroImage, HeroImageProps } from './HeroImage';
import { LayoutContext, Scroll } from '../../providers/Layout';

interface HeroProps {
  image?: HeroImageProps;
  title: string;
  subTitle?: string;
}

const HeroContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 0 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
`;

const H1 = styled.h1`
  color: #FFFFFF;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(1.3125vw + 13.8px); /* 1920: 39, 320: 18 */
  line-height: 1.25em;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-align: center;
  max-width: 19em;
  margin: 0 2.5em;
  margin-top: calc(300px - 40vh);
  @media (min-width: 770px) {
    margin-top: calc(43.47826086956522vw + -334.7826086956522px); /* 1920: 500, 770: 0 */
  }
`;

const H2 = styled.h2`
  color: #FFFFFF;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.78125vw + 8px); /* 1920: 23, 320: 10.5 */
  line-height: 1.25em;
  text-transform: uppercase;
  letter-spacing: 1.25px;
  text-align: center;
  margin-top: 0.25em;
  max-width: 27em;
  margin: 0.5em 5em;
`;

const Hero: FC<HeroProps> = ({
  image,
  title,
  subTitle,
  children,
  ...props
}) => {
  return (
    <HeroContainer {...props}>
      {title && (
        <H1>
          {title}
        </H1>
      )}
      {subTitle && (
        <H2>
          {subTitle}
        </H2>
      )}
      {children}
      {image && (
        <HeroImage {...image} />
      )}
    </HeroContainer>
  );
};

export { Hero };
