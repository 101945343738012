import styled from 'styled-components';
import { CircularPicture } from '../../components/CircularPicture';

interface TeacherProps {
  description: string;
  name: string;
  image: string;
  role?: string;
}

const TeacherContainer = styled.div`
  flex: 1;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2em;

  @media (max-width: 900px) {
    margin: 0 0 5em 0;
    max-width: 400px;
  }
`;

const Title = styled.div<{ hasRole: boolean }>`
  width: 75%;
  font-family: League Spartan;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 48px 0px ${({ hasRole }) => hasRole ? 4 : 36}px 0px;
`;

const Role = styled.div`
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  white-space: pre-wrap;
`;

const TeacherPicture = styled(CircularPicture)`
  width: 200px;
  height: 200px;
`;

function Teacher(props: TeacherProps) {
  const hasRole = Boolean(props.role);
  return (
    <TeacherContainer>
      <TeacherPicture src={props.image} alt={props.name} />
      <Title hasRole={hasRole}>
        {props.name}
      </Title>
      {hasRole && (
        <Role>
          {props.role}
        </Role>
      )}
      <Description>
        {props.description}
      </Description>
    </TeacherContainer>
  );
}

export default Teacher;
