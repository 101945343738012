import { useServices } from "../../../hooks";
import { useMemo } from "react";
import styled from "styled-components";
import { TrainingCard } from "./TrainingCard";

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const TrainingCardsContainer = () => {
  const { data } = useServices();

  const cards = useMemo(() => {
    return data.training?.sort((a, b) => {
      if (a.important && !b.important) return 1;
      else if (b.important && !a.important) return -1;
      return 0;
    });
  }, [data]);

  return (
    <Container>
      {cards?.map((card) => {
        return (
          <li key={card.id}>
            <TrainingCard
              title={card.title}
              shortDescription={card.short_description}
              longDescription={card.long_description}
              svgUrl={card.vector.url}
              svgAlt={card.vector.alt}
              isImportant={card.important}
            />
          </li>
        );
      })}
    </Container>
  );
};
