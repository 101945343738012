import { FC, createContext, useEffect, useMemo, useRef } from 'react';

interface AnimationFrameProps {
}

type Callback = (timestamp?: number) => void;

const AnimationFrameContext = createContext({
  request: (_: Callback) => () => {},
});

const AnimationFrame: FC<AnimationFrameProps> = ({ children }) => {
  const listeners = useRef<Array<Callback>>([]);

  const context = useMemo(() => ({
    request: (callback: Callback) => {
      listeners.current.push(callback);
      return () => listeners.current.splice(listeners.current.indexOf(callback), 1);
    },
  }), [listeners]);

  useEffect(() => {
    let requestId: number;

    function step(timestamp: number) {
      let i = listeners.current.length;
      while (i--) listeners.current[i](timestamp);
      requestId = requestAnimationFrame(step);
    }

    requestId = requestAnimationFrame(step);
    return () => cancelAnimationFrame(requestId);
  }, []);

  return (
    <AnimationFrameContext.Provider value={context}>
      {children}
    </AnimationFrameContext.Provider>
  );
};

export { AnimationFrame, AnimationFrameContext };
