import styled from 'styled-components';

interface FacebookProps {
}

const FacebookContainer = styled.svg`
  width: 26px;
  height: 26px;
  -webkit-tap-highlight-color: transparent;

  position: relative;
  left: 3px;
`;

function Facebook(props: FacebookProps) {
  return (
    <FacebookContainer {...props} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M25.5306 13.6584C25.5306 6.60753 19.8161 0.893066 12.7653 0.893066C5.71447 0.893066 0 6.60753 0 13.6584C0 20.7092 5.71447 26.4237 12.7653 26.4237C12.8401 26.4237 12.9149 26.4237 12.9897 26.4187V16.4857H10.2471V13.2894H12.9897V10.9358C12.9897 8.20818 14.6552 6.72222 17.0885 6.72222C18.2554 6.72222 19.2576 6.80699 19.5469 6.84688V9.69913H17.8714C16.55 9.69913 16.2907 10.3274 16.2907 11.2499V13.2844H19.4571L19.0432 16.4807H16.2907V25.93C21.6262 24.3992 25.5306 19.4875 25.5306 13.6584Z' fill='currentColor' />
    </FacebookContainer>
  );
}

export { Facebook };
