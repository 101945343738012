const menuItems = [
  {
    name: "inicio",
    link: {
      to: "home.html",
    },
  },
  {
    name: "shows",
    link: {
      to: "shows.html",
    },
  },
  {
    name: "escuela",
    link: {
      to: "school.html",
    },
  },
  {
    name: "el grupo",
    link: {
      to: "about.html",
    },
  },
  {
    name: "corporate",
    link: {
      to: "teams.html",
      target: "_blank",
    },
  },
  {
    name: "contacto",
    link: {
      to: "contact.html",
    },
  },
];

export { menuItems };
