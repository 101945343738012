import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { clamp, lerp } from "../../../utils/math";
import { colors, zIndexes } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";
import { useServices } from "../../../hooks";

interface FacilitatorCardProps {
  name: string;
  shortDescription: string;
  longDescription: string;
  imageAlt: string;
  imageURL: string;
}

const Card = styled.div`
  background: transparent;
  height: 100%;
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
`;

const FrontCardContainer = styled.div`
  border-radius: 30px;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`;

const Image = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`;

const FrontText = styled.div`
  background: linear-gradient(180deg, #03144b00 48%, #384e68 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: flex-end;
  padding: calc(16px + 2vw) calc(16px + 2vw);
  position: relative;
  width: 100%;
  z-index: ${zIndexes["overlay"]};
`;

const BackText = styled.div`
  color: ${colors.mainText};
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: calc(16px + 2vw) calc(16px + 2vw);
`;

const Name = styled.p`
  color: ${colors.mainText};
  ${typographies.sectionSubtitle}
`;

const ShortDescription = styled.p`
  color: ${colors.secondaryComponent};
  ${typographies.facilitatiorFrontText}
`;

const LongDescription = styled.p`
  font-size: clamp(15px, calc(15px + 0.0625 * (100vw - 1300px)), 16px);
  font-weight: 600;
`;

const ReadMoreText = styled.p`
  color: ${colors.mainText};
  cursor: pointer;
  text-decoration: underline;
  ${typographies.commonTextBold}
`;

const BackCardContainer = styled.div`
  border-radius: 30px;
  background: ${colors.tertiaryComponent};
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
  transform-style: preserve-3d;
  width: 100%;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
`;

export const FacilitatorCard: FC<FacilitatorCardProps> = ({
  name,
  shortDescription,
  longDescription,
  imageURL,
  imageAlt,
}) => {
  const { data } = useServices();
  const readMoreText = useMemo(() => {
    return data.enterprise_page?.facilitators[0].read_more_text;
  }, []);

  const [finalRotation, setFinalRotation] = useState(0);
  const [rotation, setRotation] = useState(0);

  const requestId = useRef<number | undefined>();

  const animate = useCallback(() => {
    setRotation((rotation) => {
      const result = clamp(0, 180, lerp(rotation, finalRotation, 0.07, 0.1));
      return result;
    });

    requestId.current = requestAnimationFrame(animate);
  }, [rotation, finalRotation]);

  useEffect(() => {
    requestId.current = requestAnimationFrame(animate);
    return () => {
      requestId.current && cancelAnimationFrame(requestId.current);
    };
  }, [animate]);

  return (
    <Card
      style={{
        transform: `${
          rotation === 0 || rotation === 180 ? "" : "perspective(2000px)"
        } rotateY(${rotation}deg)`,
        willChange: rotation === 0 || rotation === 180 ? "" : "transform",
        pointerEvents: rotation === 0 || rotation === 180 ? "all" : "none",
      }}
    >
      <FrontCardContainer>
        <Image src={imageURL} alt={imageAlt} />
        <FrontText>
          <Name>{name}</Name>
          <ShortDescription>{shortDescription}</ShortDescription>
          <ReadMoreText
            onClick={() => {
              setFinalRotation(180);
            }}
          >
            {readMoreText}
          </ReadMoreText>
        </FrontText>
      </FrontCardContainer>
      <BackCardContainer
        onClick={() => {
          setFinalRotation(0);
        }}
      >
        <BackText>
          <Name>{name}</Name>
          <LongDescription>{longDescription}</LongDescription>
        </BackText>
      </BackCardContainer>
    </Card>
  );
};
