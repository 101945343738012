import getDateFingerprint from './getDateFingerprint';

abstract class FingerprintLoader {
  protected loading = false;

  constructor(protected onLoad: (fingerprint: string) => void) {}

  load(): void {}
  cancel(): void {
    this.loading = false;
  }
}

export { getDateFingerprint, FingerprintLoader };
