import { FC, ReactNode, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { CollapsibleButton, CollapsibleButtonProps } from '../CollapsibleButton';
import { clamp, round } from '../utils';
import { useAnimationFrame } from '../../hooks';

interface SubmitButtonProps extends Omit<CollapsibleButtonProps, 'open' | 'extraContent' | 'ref'> {
  disabled?: boolean;
  submitting?: boolean;
  success?: boolean;
  from: number;
  to: number;
  children?: ReactNode;
}

const CollapsibleButtonStyled = styled(CollapsibleButton)<{ disabled?: boolean }>`
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'init'};
  font-size: 18px;
  color: white;
  font-family: League Spartan;
`;

const Check = styled.svg<{ checked?: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  stroke-dashoffset: ${({ checked }) => checked ? 0 : 30}px;
  transition: stroke-dashoffset 0.2s cubic-bezier(.48,.63,.44,-0.25);
`;

const rotate = keyframes`
  0.0%{
      transform: rotate(-10deg);
  }
  50%{
      transform: rotate(730deg);
  }
  100%{
      transform: rotate(-10deg);
  }
`;
const rotateAnimation = css`
  animation: ${rotate} 2s cubic-bezier(.7,0,.3,1) -0.1s infinite alternate none;
`
const Submiting = styled.svg<{ show?: boolean }>`
  position: absolute;
  width: 40px;
  height: 40px;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 0.15s;
  ${({ show }) => show ? rotateAnimation : ''}
`;

const SubmitButton = forwardRef<HTMLDivElement, SubmitButtonProps>((props, ref) => {
  const [open, setOpen] = useState(0);
  const openCurrent = useRef(0);
  const [openTarget, setOpenTarget] = useState(0);

  useEffect(() => setOpenTarget(props.disabled ? 0 : 1), [props.disabled]);

  const frame = useRef(0);
  useAnimationFrame(() => {
    if (frame.current++ % 3 === 0) {
      const current = openCurrent.current;
      const delta = openTarget - current;
      const progress = 1 - Math.abs(delta);
      if (progress < 1) {
        const next = clamp(0, 1, current + 0.2 * (delta > 0 ? 1 : - 1));
        const nextRounded = round(next);
        openCurrent.current = nextRounded;
        setOpen(nextRounded);
      }
    }
  }, [openCurrent.current, openTarget]);

  return (
    <CollapsibleButtonStyled
      {...props}
      disabled={props.disabled}
      ref={ref}
      open={open}
      extraContent={(
        <>
          <Check version="1.1" xmlns="http://www.w3.org/2000/svg" checked={open < 0.1 && props.success}>
            <polyline points="2,12 7,18 16,3" fill="none" stroke="#e4ffdfd6" strokeWidth="2px" strokeLinecap="round" strokeDasharray="30px" />
          </Check>
          <Submiting version="1.1" xmlns="http://www.w3.org/2000/svg" show={open < 0.1 && props.submitting}>
            <path d="M 28 33 A 15 15 0 0 1 13 33" fill="none" stroke="white" strokeWidth="2px" strokeLinecap="round" />
          </Submiting>
        </>
      )}
    />
  );
});

export { SubmitButton };
