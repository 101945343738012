import { FC } from 'react';
import { AnimationFrame } from './AnimationFrame';
import { Cookies } from './Cookies';
import { Helmet } from './Helmet';
import { Layout } from './Layout';
import { Style } from './Style';
import { Router } from './Router';
import { Services } from './Services';
import { Context, Environment } from '../types';

interface ProvidersProps {
  environment: Environment;
  context?: Context;
}

const Providers: FC<ProvidersProps> = ({ context, environment, children }) => (
  <Services context={context} environment={environment}>
    <Style>
      <Helmet environment={environment} context={context?.helmet}>
        <Cookies environment={environment}>
          <Router environment={environment} context={context?.router}>
            <AnimationFrame>
              <Layout environment={environment}>
                {children}
              </Layout>
            </AnimationFrame>
          </Router>
        </Cookies>
      </Helmet>
    </Style>
  </Services>
);

export { Providers };
