import { forwardRef } from 'react';
import styled from 'styled-components';
import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import { es } from 'date-fns/locale';
import { Flags } from './Flags';

export interface EventProps {
  id: number;
  title: string;
  startDate: Date;
  endDate: Date;
  image: {
    src: string;
    alt: string;
  };
  isOnline?: boolean;
}

const teamupUrl = 'https://goteamup.com/p/3640530-esimpro-escuela';

const EventContainer = styled.div`
  flex: 0 0 270px;
  position: relative;
  margin: 0 5px;
`;

const EventAnchor = styled.a`
  color: white;
`;

const PictureContainer = styled.div`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
`;

const Picture = styled.img`
  width: 100%;
`;

const FlagsStyled = styled(Flags)`
  position: absolute;
  top: 8px;
  right: 10px;
`;

const PictureOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 40%;
  left: 0;
  bottom: 0;
  background: linear-gradient(0deg, rgba(0,21,76,0.7) 0%, rgba(0,21,76,0) 100%);
`;

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  margin: -2.5em 0 0.75em 0;
  position: relative;
  z-index: 1;
`;

const WorkshopDate = styled.p`
  font-family: League Spartan;
  font-size: 28px;
  margin-right: 0.5em;
`;

const WorkshopWeekday = styled.p`
  font-size: 14px;
  margin-top: 1em;
`;

const Title = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: calc(-0.125vw + 16.4px); /* 1920: 14, 320: 16 */
  margin-bottom: 0.25em;
`;

const Time = styled.div`
  display: flex;
  font-size: calc(-0.125vw + 16.4px); /* 1920: 14, 320: 16 */
`;
const Duration = styled.p`
  color: #9BFFED;
  margin-right: 0.25em;
`;
const Hours = styled.p`
`;

const formatDistanceLocale = {
  xSeconds: '',
  xMinutes: '{{count}}min',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  xMonths: '{{count}}m',
  xYears: '{{count}}a',
};
const shortEs = {
  formatDistance: (token: string, count: string) =>
    formatDistanceLocale[token as keyof typeof formatDistanceLocale].replace('{{count}}', count)
};

const Event = forwardRef<HTMLDivElement, EventProps>(({
  id,
  title,
  startDate,
  endDate,
  image,
  isOnline,
}, ref) => {
  return (
    <EventContainer ref={ref}>
      <EventAnchor href={`${teamupUrl}/e/${id}`} target='_blank'>
        <PictureContainer>
          <Picture src={image.src} alt={image.src} />
          <FlagsStyled online={isOnline} />
          <PictureOverlay />
        </PictureContainer>
        <DateContainer>
          <WorkshopDate>
            {format(startDate, 'd LLL', { locale: es })}
          </WorkshopDate>
          <WorkshopWeekday>
            {format(startDate, 'EEEE', { locale: es })}
          </WorkshopWeekday>
        </DateContainer>
        <Title>
          {title}
        </Title>
        <Time>
          <Duration>
            {`${formatDuration(
              intervalToDuration({
                start: startDate,
                end: endDate,
              }),
              { locale: shortEs },
            )} ·`}
          </Duration>
          <Hours>
            {`${format(startDate, 'HH:mm', { locale: es })} - ${format(endDate, 'HH:mm', { locale: es })}`}
          </Hours>
        </Time>
      </EventAnchor>
    </EventContainer>
  );
});

export { Event };
