import { useServices } from "../../../hooks";
import { Fragment, useMemo, useState } from "react";
import styled from "styled-components";
import { TestimonyCard } from "./TestimonyCard";
import { SeparatorSvg } from "../assets/Separator.svg";
import { getMobileMediaQuery } from "../../../utils/mediaQuery";
import { Arrow } from "../../../components/Arrow";
import { colors } from "../../../styles/variables";

interface ContainerProps {
  responsiveStyles: string;
}

const Mask = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
`;

const Container = styled.ul<ContainerProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  ${({ responsiveStyles }) =>
    getMobileMediaQuery(`
  flex-direction: row;
  gap: 0;
  position: relative;
  transition: left 0.3s;
  ${responsiveStyles}
`)}
`;

const ListItem = styled.li`
  ${getMobileMediaQuery(`
display:flex;
flex-direction: row;
justify-content:center;
`)}
`;

const SeparatorContainer = styled.div`
  ${getMobileMediaQuery(`
  display: none;
`)}
`;

const ArrowContainer = styled.div`
  display: none;
  width: 100%;
  ${getMobileMediaQuery(`
  align-items: center;
  display: flex;
  justify-content: space-between;
`)}

  & button:first-child {
    transform: rotateZ(180deg);
  }
`;

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  color: ${colors.secondaryComponent};
  cursor: pointer;
  transition: opacity 0.3s;

  &:disabled {
    cursor: default;
    opacity: 0;
  }
`;

const CustomArrow = styled(Arrow)`
  width: 60px;
`;

export const TestimonyCardsContainer = () => {
  const { data } = useServices();
  const [currentTestimony, setCurrentTestimony] = useState(0);

  const cards = useMemo(() => {
    return data.testimony;
  }, [data]);

  return (
    <>
      <Mask>
        <Container
          responsiveStyles={`
          left: -${currentTestimony * 100}%;
          width: ${(cards?.length || 0) * 100}%;
          `}
        >
          {cards?.map((card, index) => {
            return (
              <Fragment key={card.id}>
                <ListItem>
                  <TestimonyCard
                    name={card.name}
                    position={card.position}
                    company={card.company}
                    scoring={card.scoring}
                    testimony={card.quote}
                  />
                </ListItem>
                <SeparatorContainer>
                  {index !== cards.length - 1 ? <SeparatorSvg /> : null}
                </SeparatorContainer>
              </Fragment>
            );
          })}
        </Container>
      </Mask>
      {cards?.length && (
        <ArrowContainer>
          <ArrowButton
            onClick={() =>
              currentTestimony - 1 >= 0 &&
              setCurrentTestimony(currentTestimony - 1)
            }
            disabled={currentTestimony <= 0}
          >
            <CustomArrow />
          </ArrowButton>
          <ArrowButton
            onClick={() =>
              currentTestimony + 1 <= cards.length - 1 &&
              setCurrentTestimony(currentTestimony + 1)
            }
            disabled={currentTestimony >= cards.length - 1}
          >
            <CustomArrow />
          </ArrowButton>
        </ArrowContainer>
      )}
    </>
  );
};
