import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import online from '../../../assets/images/online.svg';

interface FlagsProps {
  online?: boolean;
}

const Text = styled.span`
  font-family: ${({ theme }) => theme.font.quicksand};
  font-size: 9px;
  overflow: hidden;
  margin-right: 0;
  max-width: 0px;
  transition: all 0.3s;
`;

const OnlineContainer = styled.div`
  cursor: default;
  pointer-events: auto;
  background-color: ${({ theme }) => theme.palette.orange};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 0 5px;
  &:hover, &:focus, &:active {
    & > ${Text} {
      max-width: 40px;
      margin-right: 0.5em;
    }
  }
`;

const Icon = styled.img`
`;

function Online(props: FlagsProps) {
  return (
    <OnlineContainer {...props} aria-haspopup='true' onClick={event => {
      event.preventDefault();
      event.stopPropagation();
    }}>
      <Text>Online</Text>
      <Icon src={online} alt='Online' />
    </OnlineContainer>
  );
}

const FlagsContainer = styled.div`
  display: flex;
`;

function Flags(props: FlagsProps) {
  return (
    <FlagsContainer {...props}>
      {props.online && (
        <Online />
      )}
    </FlagsContainer>
  );
}

export { Flags };
