import { HelmetData } from "react-helmet";

export enum Environment {
  Browser,
  Static,
}

export declare namespace Teamup {
  export interface Event {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
    venue: {
      is_online: boolean;
    };
  }
}

export declare namespace DB {
  export interface Show {
    id: number;
    name: string;
    start_time: string;
    end_time: string;
    venue: {
      is_online: boolean;
    };
  }
}

export interface PrismicImage {
  dimensions: { width: number; height: number };
  alt: string;
  copyright: null;
  url: string;
  id: string;
  edit: { x: number; y: number; zoom: number; background: string };
}

export type PrismicMediaKind = "all" | "image";

export interface PrismicMedia {
  kind: PrismicMediaKind;
  link_type: string;
  name: string;
  size: string;
  url: string;
}

export interface HyperLinkSpan {
  data: {
    target: "_blank" | undefined;
    url: string;
  };
  end: number;
  start: number;
  type: string;
}

export interface HyperLinkRichText {
  direction: string;
  spans: HyperLinkSpan[];
  text: string;
  type: string;
}

export interface PrismicData {
  event_service: Array<{
    id: string;
    image: PrismicImage;
    title: string;
    subtitle: string;
    description: string;
  }>;
  facilitators: Array<{
    id: string;
    image: PrismicImage;
    name: string;
    short_description: string;
    long_description: string;
  }>;
  training: Array<{
    id: string;
    title: string;
    short_description: string;
    long_description: string;
    vector: PrismicImage;
    important: boolean;
  }>;
  testimony: Array<{
    id: string;
    name: string;
    position: string;
    company: string;
    scoring: string;
    quote: string;
  }>;
  enterprise_page: {
    header: Array<{ logo: PrismicImage; button: string }>;
    video: PrismicMedia;
    page_title: string;
    intro: Array<{
      image: PrismicImage;
      title: string;
      description: string;
    }>;
    trainings: Array<{
      title: string;
      subtitle: string;
      button: string;
    }>;
    events: Array<{ title: string; button: string }>;
    facilitators: Array<{
      title: string;
      subtitle: string;
      read_more_text: string;
    }>;
    testimonials: Array<{ title: string; subtitle: string }>;
    contact: Array<{
      title: string;
      name_placeholder: string;
      contact_surname_placeholder: string;
      contact_email_placeholder: string;
      contact_about_label: string;
      contact_message_placeholder: string;
      privacy_policy_text: HyperLinkRichText[];
      contact_button: string;
      success_message: string;
      error_message: string;
      loading_message: string;
    }>;
    body: Array<{
      primary: { label: string };
      id: string;
    }>;
  };
}

export interface Context {
  helmet: {
    helmet: HelmetData;
  };
  router: {
    path: string;
  };
  style: {
    css: Set<string>;
  };
  data: {
    teamupEvents: Array<Teamup.Event>;
    shows: Array<DB.Show>;
  } & {
    [key in keyof PrismicData]?: PrismicData[key];
  };
}

export enum WishlistTypes {
  ShowFormat = "showFormat",
  SchoolWorkshop = "schoolWorkshop",
}

export interface Storage {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
}
