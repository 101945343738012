import styled from 'styled-components';
import stair from './stair.svg';

export enum CourseLevel {
  Beginner,
  Intermediate,
  Advanced,
};

interface CourseProps {
  day: string;
  hour: string;
  title: string;
  level: CourseLevel;
  flag?: string;
}

const CourseContainer = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  margin-right: 20px;
  margin-bottom: 55px;
`;

const Icon = styled.div`
  position: relative;
  padding-top: 10px;
`;

const Stair = styled.img`
  margin-right: 40px;
`;

const ball = {
  left: {
    [CourseLevel.Beginner]: '4px',
    [CourseLevel.Intermediate]: '23px',
    [CourseLevel.Advanced]: '46px',
  },
  top: {
    [CourseLevel.Beginner]: '40px',
    [CourseLevel.Intermediate]: '28px',
    [CourseLevel.Advanced]: '13px',
  },
}

interface BallProps {
  level: CourseLevel;
}
const Ball = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: white;
  left: ${(props: BallProps) => ball.left[props.level]};
  top: ${(props: BallProps) => ball.top[props.level]};
`;

const Info = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.div`
  position: relative;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.25em;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 1.25em;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

const FlagContainer = styled.div`
  left: 0.5em;
  position: relative;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
`;

const Flag = styled.img`
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  position: relative;
  left: -1px;
  top: -1px;
`;

function Course(props: CourseProps) {
  return (
    <CourseContainer>
      <Icon>
        <Stair src={stair} />
        <Ball level={props.level} />
      </Icon>
      <Info>
        <Title>
          {props.title}
          {props.flag && (
            <FlagContainer>
              <Flag src={props.flag} />
            </FlagContainer>
          )}
        </Title>
        <Subtitle>
          {props.day}<br />{props.hour}
        </Subtitle>
      </Info>
    </CourseContainer>
  );
}

export default Course;
