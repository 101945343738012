import styled from 'styled-components';
import cookie from '../assets/images/cookie-svgrepo-com.svg';

interface CookieManagerProps {
}

const CookieManagerContainer = styled.button`
  cursor: pointer;
`;

function CookieManager(props: CookieManagerProps) {
  return (
    <CookieManagerContainer {...props} onClick={() => (window as any).openCookiePreferences()}>
      <img src={cookie} alt='Cookie preferences' />
    </CookieManagerContainer>
  );
}

export { CookieManager };
