import styled from 'styled-components';
import Course, { CourseLevel } from './Course';
import flagEn from './en.jpg';

interface CoursesProps {
}

const CoursesContainer = styled.div`
  margin-top: 80px;
  width: 80%;
  display: grid;
  margin-bottom: 50px;

  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 630px) {
    grid-template-columns: 1fr;
  }
`;

function Courses(_: CoursesProps) {
  return (
    <CoursesContainer>
      <Course
        level={CourseLevel.Beginner}
        title='Iniciación'
        day='Martes'
        hour='19:00h - 21:30h'
      />
      <Course
        level={CourseLevel.Beginner}
        title='Beginner'
        day='Monday'
        hour='7:00PM - 9:30PM'
        flag={flagEn}
      />
      <Course
        level={CourseLevel.Intermediate}
        title='Intermedio'
        day='Lunes'
        hour='19:00h - 21:30h'
      />
      <Course
        level={CourseLevel.Intermediate}
        title='Intermediate'
        day='Thursday'
        hour='7:00PM - 9:00PM'
        flag={flagEn}
      />
      <Course
        level={CourseLevel.Advanced}
        title='Avanzado'
        day='Miércoles'
        hour='19:00h - 21:30h'
      />
    </CoursesContainer>
  );
}

export default Courses;
