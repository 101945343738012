import { useContext, useEffect } from 'react';
import { AnimationFrameContext } from '../providers/AnimationFrame';

function useAnimationFrame(callback: () => void, dependencies: Array<unknown>) {
  const animationFrame = useContext(AnimationFrameContext);

  useEffect(() => animationFrame.request(callback), dependencies);
}

export { useAnimationFrame };
