import { useContext, useEffect, useState } from 'react';
import { LayoutContext, Scroll } from '../providers/Layout';

function useLayoutScroll() {
  const [scroll, setScroll] = useState<Scroll>({
    top: 0,
    bottom: 0,
    end: false,
  });
  const scrollContext = useContext(LayoutContext);

  useEffect(() => scrollContext.request(setScroll), []);

  return scroll;
}

export { useLayoutScroll, LayoutContext };
export type { Scroll };
