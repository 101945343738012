import { ComponentProps } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ScrollText } from './ScrollText';
import { ScrollButton } from '../../components/ScrollButton';
import { Content as CollapsibleButtonContent } from '../../components/CollapsibleButton';
import { CircularPicture } from '../../components/CircularPicture';

import improCampa23 from './improCampa23.jpg';

interface PromoSchoolProps {
}

const PromoSchoolContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 630px) {
    flex-direction: column;
  }

  padding-left: calc(9.375vw + -60px);
`;

const ImageContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 100%;
`;

const Texts = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;
  padding: 1em;
  margin-top: 2em;
`;

const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.375vw + 22.8px); /* 1920: 30, 320: 24 */
  line-height: 1.25em;
  text-align: center;

  max-width: calc(5.25vw + 319.2px); /* 1920: 420, 320: 336 */
  margin-bottom: 1em;
`;

const TitleYes = styled(ScrollText)<ComponentProps<typeof ScrollText>>`
`;

const DescriptionContainer = styled.div`
  font-size: calc(1.625vw + 17px);
  max-width: calc(5.25vw + 319.2px); /* 1920: 420, 320: 336 */
`;

const Description = styled.div`
  font-family: Quicksand;
  font-size: calc(0.1875vw + 14.4px); /* 1920: 18, 320: 15 */
  line-height: 1.25em;

  margin-bottom: 3em;
`;

const SignupButton = styled(ScrollButton)`
  font-size: 18px;
  color: white;
  font-family: League Spartan;
  ${CollapsibleButtonContent} {
    transform: translate(-2px, -2px);
  };
`;

function PromoSchool(_: PromoSchoolProps) {
  return (
    <PromoSchoolContainer>
      <ImageContainer>
        <CircularPicture src={improCampa23} alt='Alumnos en el ImproCampa' />
      </ImageContainer>
      <Texts>
        <Title>
          Lánzate, di <TitleYes calc='1.625vw + 18.8px' factor={0.2}>SÍ</TitleYes> y aprende en la escuela
        </Title>
        <DescriptionContainer>
          <Description>
            Aprende la técnica, entrena la creatividad, gana confianza, mejora tu concentración y ante todo diviértete.
          </Description>
          <Description>
            Tenemos cursos todos los días, tanto por las mañanas como por las tardes, varios niveles y disponibles en español e inglés. Vamos, que ahora  no tienes motivos para no ir a clase.
          </Description>
        </DescriptionContainer>
        <Link to='/school.html'>
          <SignupButton
            borderColor='#FD5D27'
            fillColor='#FD5D27'
            from={0.8}
            to={0.7}
            onClick={() => {}}
          >
            apúntate
          </SignupButton>
        </Link>
      </Texts>
    </PromoSchoolContainer>
  );
}

export { PromoSchool };
