import { createContext, useContext } from 'react';
import { getLiveshowApi, messageTypes, viewerTypes } from './services/liveshowApi';
import { getUseLiveshowApi } from './hooks/liveshowApi';

let Singleton;
(function() {
  let instance: any;
  Singleton = (getInstance: any) => (...args: any) => {
    if (instance) return instance;
    instance = getInstance(...args);
    return instance;
  };
}());

const getLiveshowContext = Singleton(() => {
  const services = {
    liveshowApi: getLiveshowApi({
      url: process.env.REACT_APP_LIVESHOW_API_URL,
      client: typeof WebSocket !== 'undefined' ? WebSocket : null,
      viewerType: viewerTypes.Audience,
    }),
  };

  const hooks = {
    useLiveshowApi: getUseLiveshowApi(services.liveshowApi),
  };

  const consts = {
    liveshowApi: {
      messageTypes,
      viewerTypes,
    },
  };

  return {
    consts,
    react: createContext(services),
    services,
    hooks,
  };
});

const useLiveshowContext = () => useContext(getLiveshowContext().react);

export {
  getLiveshowContext,
  useLiveshowContext,
}
