import styled from 'styled-components';
import Head from './Head';
import { Person } from './Person';
import { WhatIsImprov } from './WhatIsImprov';
import { DontStopImprov } from './DontStopImprov';
import { Timeline } from './Timeline';
import { CircularPicture } from '../../components/CircularPicture';
import { SectionHeader } from '../../components/SectionHeader';
import { Hero, HeroImage } from '../../components/Hero';
import { Footer } from '../../components/Footer';

import hero from './assets/hero.jpg';
// @ts-ignore
import heroPlaceholder from './assets/heroPlaceholder.jpg?inline';

import gon from './assets/gon.jpg';
import paula from './assets/paula.jpg';
import ale from './assets/ale.jpg';
import irene from './assets/irene.jpg';
import cris from './assets/cris.jpg';
import irina from './assets/irina.jpg';
import kevin from './assets/kevin.jpg';
import eloi from './assets/eloi.jpg';
import nico from './assets/nico.jpg';
import lourdes from './assets/lourdes.jpg';
import denis from './assets/denis.jpg';
import mario from './assets/mario.jpg';

interface AboutProps {
}

const AboutContainer = styled.div`
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding: calc(-1.875vw + 50px); /* 1920: 0, 320: 30, aprox. 50px was 36px */
  padding-bottom: 200px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PersonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin: calc(0.5625vw + 5.2px) calc(0.6875vw + 4.8px); // 16|7, 18|7
  }
  margin-top: 1em;

  @media (max-width: 1100px) {
    max-width: 600px;
  }
`;

function About(_: AboutProps) {
  return (
    <AboutContainer>
      <Head />
      <Hero
        title='¿Qué es y quién hay detrás de ESIMPRO?'
        image={{
          src: hero,
          alt: 'A lot of people on stage',
          opacity: 0.8,
          placeholder: heroPlaceholder,
        }}
      />
      <Content>
        <WhatIsImprov />
        <SectionHeader
          title='El grupo'
          description='Somos mucha gente haciendo mucha impro'
          separator
        />
        <PersonContainer>
          <Person picture={gon} name='Gonzalo Piñán' />
          <Person picture={ale} name='Alejandro Goiri' />
          <Person picture={paula} name='Paula Galimberti' />
          <Person picture={irene} name='Irene Rujas' />
          <Person picture={kevin} name='Kevin Giarrocco' />
          <Person picture={cris} name='Cris Blanco' />
          <Person picture={eloi} name='Eloi Marín' />
        </PersonContainer>
        <DontStopImprov />
        <Timeline />
        <Footer />
      </Content>
    </AboutContainer>
  );
}

export { About };
