import styled from 'styled-components';

interface DontStopImprovProps {
}

const DontStopImprovContainer = styled.div`
  margin-bottom: 8em;
  margin: 8em 0;
`;

const Title = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: 28px;
  color: white;
  text-align: center;
  margin: 1em 0;
`;

const Description = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: 16px;
  color: white;
  text-align: center;
`;

function DontStopImprov(_: DontStopImprovProps) {
  return (
    <DontStopImprovContainer>
      <Description>
        Como toda historia, tuvimos una presentación, un nudo y... seguimos en el nudo.
      </Description>
      <Title>
        ¡Que no se acabe la impro!
      </Title>
    </DontStopImprovContainer>
  );
}

export { DontStopImprov };
