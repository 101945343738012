import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <title>Las caras de la compañía | ESIMPRO</title>
      <meta name="description" content="Descubre quién hay detrás de la compañía de ESIMPRO." />
    </Helmet>
  );
}

export default Head;
