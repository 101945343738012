import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Context, Environment } from '../types';

interface HelmetProps {
  environment: Environment;
  context?: Context['helmet'];
}

const getProviderProps = (environment: Environment, context?: Context['helmet']) => (
  environment === Environment.Static && context ? { context } : {}
);

const Helmet: FC<HelmetProps> = ({ environment, context, children }) => (
  <HelmetProvider {...getProviderProps(environment, context)}>
    {children}
  </HelmetProvider>
);

export { Helmet };
