import styled from 'styled-components';
import { Hider } from './Hider';

interface DotsProps {
}

const DotsContainer = styled.div`
  position: absolute;
  top: 200px;
  left: -30px;
  width: 100vw;

  @media (min-width: 631px) {
    top: calc(20.17067494181536vw + 37.72304111714507px); /* 1920: 425, 631: 165 */
  }
`;

const Svg = styled.svg`
  width: calc(12.5vw + 360px);
  height: 1000px;

  @media (max-width: 630px) {
    display: none;
  }
`;

const SvgMobileContainer = styled.div`
  @media (min-width: 631px) {
    display: none;
  }
`;
const SvgMobile1 = styled.svg`
  width: 84px;
  height: 249px;

  position: relative;
  top: calc(9.67741935483871vw + -30.967741935483872px);
  left: 20px;
`;
const SvgMobile2 = styled.svg`
  width: 31px;
  height: 118px;

  position: relative;
  top: calc(22.58064516129032vw + 187.74193548387098px);
  left: calc(20.967741935483872vw + 7.903225806451616px);
`;
const SvgMobile3 = styled.svg`
  width: 52px;
  height: 137px;

  position: relative;
  top: calc(32.25806451612903vw + 756.7741935483871px);
  left: calc(6.451612903225806vw + -50.64516129032258px);
`;

function Dots(_: DotsProps) {
  return (
    <DotsContainer>
      <Svg viewBox="0 0 600 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M244.497 1018C420 795 386.5 934 543 732.5C561.507 708.671 585.39 668.494 599.198 632.5M3 3C44.7746 79.6254 114.503 159.639 226.498 220.802C332.951 278.939 413.728 318.278 473.482 359.783" stroke="#9BFFED" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 50"/>
      </Svg>
      <SvgMobileContainer>
        <SvgMobile1 viewBox="0 0 84 249" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M83 1C83 1 1 52.6977 1 152.902C1 233.502 40.8919 248 40.8919 248" stroke="#9BFFED" strokeWidth="1.94533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.39 19.45"/>
        </SvgMobile1>
        <SvgMobile2 viewBox="0 0 31 118" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.71645 1C61 66.5 8.71645 116.5 1.71645 116.5" stroke="#9BFFED" strokeWidth="1.94533" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.39 19.45"/>
        </SvgMobile2>
        <SvgMobile3 viewBox="0 0 52 137" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5937 1.78167C-32.453 90.1414 54 89.4998 49.9999 135" stroke="#9BFFED" strokeWidth="2.22531" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="0.45 22.25"/>
        </SvgMobile3>
      </SvgMobileContainer>
      <Hider />
    </DotsContainer>
  );
}

export { Dots };
