import {
  FC,
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { Environment } from "../types";
import { useServices, useAnimationFrame, useLayoutScroll } from "../hooks";
import { Header } from "../components/Header";
import { ScrollArrow } from "../components/ScrollArrow";

interface LayoutProps {
  environment: Environment;
}

interface Layout {
  width: number;
  height: number;
  portrait: boolean;
}

interface Scroll {
  top: number;
  bottom: number;
  end: boolean;
}

type LayoutCallback = (_: Layout) => void;
type ScrollCallback = (_: Scroll) => void;

const defaultLayout = {
  width: 320,
  height: 568,
  portrait: true,
};

const LayoutContext = createContext<{
  request: (_: ScrollCallback) => () => void;
  layoutSubscribe: (_: LayoutCallback) => () => void;
  getScroll: () => number;
  getLayout: () => Layout;
  modalPortal: HTMLDivElement | null;
  scrollUp: () => void;
}>({
  request: (_: ScrollCallback) => () => {},
  layoutSubscribe: (_: LayoutCallback) => () => {},
  getScroll: () => 0,
  getLayout: () => defaultLayout,
  modalPortal: null,
  scrollUp: () => {},
});

const RootLayout = styled.div`
  position: relative;
  z-index: 0;
  background-color: ${({ theme }) => theme.palette.background};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  perspective: 1px;
  perspective-origin: 0 0;
  scroll-behavior: smooth;
`;

const ModalPortal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
`;

const Layout: FC<LayoutProps> = ({ children, environment }) => {
  const location = useLocation();
  const rootLayoutRef = useRef<HTMLDivElement>(null);
  const [modalPortal, setModalPortal] = useState<HTMLDivElement | null>(null);

  const layoutListeners = useMemo(() => new Set<LayoutCallback>(), []);

  const scrollListeners = useMemo(() => new Set<ScrollCallback>(), []);
  const scroll = useRef(0);

  const getLayout = useCallback(() => {
    if (environment === Environment.Browser) {
      const { innerWidth, innerHeight } = window;
      return {
        width: innerWidth,
        height: innerHeight,
        portrait: innerWidth > innerHeight,
      };
    }
    return defaultLayout;
  }, [environment]);

  useAnimationFrame(() => {
    if (rootLayoutRef.current) {
      const currentScroll = rootLayoutRef.current.scrollTop;
      if (scroll.current !== currentScroll) {
        scroll.current = currentScroll;
        const scrollContext = {
          top: scroll.current,
          bottom: scroll.current + rootLayoutRef.current.clientHeight,
          end: false,
        };
        scrollContext.end =
          scrollContext.bottom >= rootLayoutRef.current.scrollHeight;
        scrollListeners.forEach((listener) => listener(scrollContext));
      }
    }
  }, [rootLayoutRef]);

  useEffect(() => {
    if (environment === Environment.Browser) {
      window.onresize = () => {
        const layout = getLayout();
        layoutListeners.forEach((listener) => listener(layout));
      };
    }
  }, [environment]);

  const scrollUp = useCallback(() => {
    if (rootLayoutRef.current) {
      rootLayoutRef.current.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  // TODO Rename, this is not scroll context anymore, but more general layout context
  const scrollContext = useMemo(
    () => ({
      request: (callback: ScrollCallback) => {
        scrollListeners.add(callback);
        return () => scrollListeners.delete(callback);
      },
      layoutSubscribe: (callback: LayoutCallback) => {
        layoutListeners.add(callback);
        return () => layoutListeners.delete(callback);
      },
      getScroll: () => rootLayoutRef.current?.scrollTop || 0,
      modalPortal,
      getLayout,
      scrollUp,
    }),
    [scrollListeners, modalPortal, scrollUp]
  );

  const inLivePage = location.pathname.includes("live");

  return (
    <LayoutContext.Provider value={scrollContext}>
      <RootLayout ref={rootLayoutRef}>
        <Header />
        {children}
      </RootLayout>
      {!inLivePage && <ScrollArrow scrollUp={scrollUp} />}
      <ModalPortal ref={(ref) => setModalPortal(ref)} />
    </LayoutContext.Provider>
  );
};

export { Layout, LayoutContext };
export type { Scroll };
