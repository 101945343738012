const clamp = (min: number, max: number, x: number) => {
  if (x < min) return min;
  if (x > max) return max;
  return x;
};

const round = (x: number) => Math.round((x + Number.EPSILON) * 100) / 100;

function easeInOutCubic(x: number): number {
  if (isNaN(x)) return 0;
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

export {
  clamp,
  round,
  easeInOutCubic,
  easeOutCubic,
};
