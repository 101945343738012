import { useContext, useEffect, useState } from 'react';
import { LayoutContext, Layout } from '../providers/Layout';

function useLayout() {
  const layoutContext = useContext(LayoutContext);
  const [layout, setLayout] = useState<Layout>(layoutContext.getLayout);

  useEffect(() => layoutContext.layoutSubscribe(setLayout), []);

  return layout;
}

export { useLayout, LayoutContext };
export type { Layout };
