export const getMobileMediaQuery = (style: string) => {
  return `
    @media (max-width: 576px) {
      ${style}
    }
  `;
};

export const getTabletMediaQuery = (style: string) => {
  return `
    @media (max-width: 850px) {
      ${style}
    }
  `;
};
