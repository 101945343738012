import { FC } from 'react';
import styled from 'styled-components';
import { useLayoutScroll } from '../../hooks';

interface ScrollTextProps {
  calc: string;
  factor: number;
}

const ScrollTextContainer = styled.span`
  transition: font-size 0.1s;
`;

const ScrollText: FC<ScrollTextProps> = ({ calc, factor, ...props }) => {
  const scroll = useLayoutScroll();
  const sizeFactor = 1 + (Math.cos(scroll.top * 0.015)) * factor;
  const style = {
    fontSize: `calc((${calc}) * ${sizeFactor})`
  };
  return <ScrollTextContainer style={style} {...props} />;
};

export { ScrollText };
