import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NewsletterModalProps } from '../components/NewsletterModal';
import { useServices } from './useServices';
import { getStorageKey, getStoredValue } from './utils';

const apiUrl = process.env.REACT_APP_API_URL;

type ProvidedProps = 'open' | 'onClose' | 'onSubscribe' | 'onNeverAsk';
type HookProps = Pick<NewsletterModalProps, ProvidedProps>;

function useNewsletterModal(source: string = 'Newsletter modal'): [HookProps, () => void] {
  const { fingerprint, storage } = useServices();
  const skip = useMemo(() => getStoredValue(storage)(getStorageKey(fingerprint, 'modal.newsletter.skip')), []);
  const [isOpen, setOpen] = useState(false);

  const open = useCallback(() => {
    if (!skip.get()) setOpen(true);
  }, [skip]);

  const onClose = useCallback(() => setOpen(false), []);

  const onSubscribe = useCallback((email: string) => {
    fetch(`${apiUrl}/newsletter`, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        source,
      }),
    });
    onClose();
    skip.set(true);
  }, []);

  const onNeverAsk = useCallback(() => {
    onClose();
    skip.set(true);
  }, [onClose]);

  const modalProps = useMemo(() => ({
    open: isOpen,
    onClose,
    onSubscribe,
    onNeverAsk
  }), [isOpen, onClose, onSubscribe, onNeverAsk]);

  return [modalProps, open];
}

export { useNewsletterModal };
