import styled from "styled-components";
import { FormField } from "../../Contact/Form/FormField";
import {
  Input,
  TextArea,
  Category,
  CategoryRow,
  Checkbox,
  CheckboxLabel,
  FormRow,
  FamilyNameField,
  PrivacyRow,
  Link,
} from "../../Contact/Form/Form";
import { useServices } from "../../../hooks";
import {
  FormEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getHyperLinkText } from "../../../utils/prismic";
import { CommonButton } from "./CommonButton";
import { typographies } from "../../../styles/typographies";
import { getMobileMediaQuery } from "../../../utils/mediaQuery";

const apiUrl = process.env.REACT_APP_API_URL;
const TIME_TO_HIDE_MESSAGE_IN_SECONDS = 3;
const TIME_TO_HIDE_MESSAGE = 1000 * TIME_TO_HIDE_MESSAGE_IN_SECONDS;

interface FormData {
  name: string;
  familyName: string;
  email: string;
  subject?: string;
  message: string;
  "t&c": boolean;
}

const defaultValues: FormData = {
  name: "",
  familyName: "",
  email: "",
  subject: undefined,
  message: "",
  "t&c": false,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  position: relative;

  @media (max-width: 630px) {
    width: 100%;
    padding: 0 calc(min(10vw, 20px)) 25px;
  }
`;

const FormContainer = styled.form`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

const GappedFormRow = styled(FormRow)`
  gap: 8px;
`;

const StaticTextArea = styled(TextArea)`
  resize: none;
  min-height: 120px;
`;

const BottomMessage = styled.p`
  bottom: -30px;
  position: absolute;
  transform: translateY(75%);
  width: 100%;
  ${typographies.contactFormMessage};
  ${getMobileMediaQuery(`
    bottom: -10px;
    padding: 0 5%;
  `)}
`;

export const ContactForm = () => {
  const { data } = useServices();
  const [formData, setFormData] = useState<FormData>(defaultValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [areSubjectsOpen, setAreSubjectsOpen] = useState<boolean>(false);

  const shouldSubmitBeDisabled = useMemo(() => {
    const conditions = [
      isLoading,
      !formData["t&c"],
      !formData.name,
      !formData.familyName,
      !formData.email,
      !formData.subject,
      !formData.message,
    ];

    return conditions.reduce(
      (prevResult, currentValue) => prevResult || currentValue,
      false
    );
  }, [formData, isLoading]);

  const onSubmit = useCallback<FormEventHandler>(
    (event) => {
      event.preventDefault();

      setIsLoading(true);
      fetch(`${apiUrl}/sendContactMessage`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          familyName: formData.familyName,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          privacy: formData["t&c"],
          category: "teams",
          newsletter: false,
        }),
      })
        .then(() => {
          setFormData(defaultValues);
          setIsSuccess(true);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [formData]
  );

  const setValue = useCallback(
    <T extends keyof FormData>(fieldName: T, newValue: FormData[T]) => {
      setFormData((prevValue) => {
        return { ...prevValue, [fieldName]: newValue };
      });
    },
    []
  );

  const getValue = useCallback(
    <T extends keyof FormData>(fieldName: T) => {
      return formData[fieldName] as FormData[T];
    },
    [formData]
  );

  const subjectValue = useMemo(() => {
    return getValue("subject");
  }, [getValue]);

  const switchSubjects = useCallback(() => {
    setAreSubjectsOpen((areOpen) => !areOpen);
  }, []);

  const contactData = useMemo(() => {
    return data.enterprise_page?.contact[0];
  }, []);

  const subjectNames = useMemo<Record<string, string> | undefined>(() => {
    return data.enterprise_page?.body.reduce<Record<string, string>>(
      (prevValue, currentElement) => {
        const currentLabel = currentElement.primary.label;
        return {
          ...prevValue,
          [currentLabel]: currentLabel,
        };
      },
      {}
    );
  }, []);

  useEffect(() => {
    if (!isError) return;

    const timer = setTimeout(() => {
      setIsError(false);
    }, TIME_TO_HIDE_MESSAGE);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;

    const timer = setTimeout(() => {
      setIsSuccess(false);
    }, TIME_TO_HIDE_MESSAGE);

    return () => {
      clearTimeout(timer);
    };
  }, [isSuccess]);

  return (
    <Container>
      <FormContainer onSubmit={onSubmit}>
        {contactData && (
          <>
            <GappedFormRow>
              <FormField
                label={contactData.name_placeholder}
                value={getValue("name")}
              >
                <Input
                  type="text"
                  value={getValue("name")}
                  onChange={(event) => setValue("name", event.target.value)}
                />
              </FormField>
              <FamilyNameField
                label={contactData.contact_surname_placeholder}
                value={getValue("familyName")}
              >
                <Input
                  type="text"
                  value={getValue("familyName")}
                  onChange={(event) =>
                    setValue("familyName", event.target.value)
                  }
                />
              </FamilyNameField>
            </GappedFormRow>
            <FormRow>
              <FormField
                label={contactData.contact_email_placeholder}
                value={getValue("email")}
              >
                <Input
                  type="email"
                  value={getValue("email")}
                  onChange={(event) => setValue("email", event.target.value)}
                />
              </FormField>
            </FormRow>
            <CategoryRow open={areSubjectsOpen}>
              <div style={{ transformOrigin: "top" }}>
                {areSubjectsOpen && subjectNames ? (
                  <>
                    {Object.entries(subjectNames).map(([subject]) => (
                      <Category
                        key={subject}
                        onClick={() => {
                          setValue("subject", subject);
                          switchSubjects();
                        }}
                      >
                        {subject}
                      </Category>
                    ))}
                  </>
                ) : (
                  <Category onClick={switchSubjects} noMargin>
                    {(subjectNames ? subjectNames[subjectValue!] : null) ??
                      contactData.contact_about_label}
                  </Category>
                )}
              </div>
            </CategoryRow>
            <FormRow style={{ marginBottom: "1em" }}>
              <FormField
                label={contactData.contact_message_placeholder}
                value={getValue("message")}
                textArea
              >
                <StaticTextArea
                  value={getValue("message")}
                  onChange={(event) => setValue("message", event.target.value)}
                />
              </FormField>
            </FormRow>
            <PrivacyRow onClick={() => setValue("t&c", !getValue("t&c"))}>
              <Checkbox checked={getValue("t&c")} />
              <CheckboxLabel>
                {getHyperLinkText(contactData.privacy_policy_text[0], Link)}
              </CheckboxLabel>
            </PrivacyRow>
          </>
        )}
        <CommonButton
          disabled={shouldSubmitBeDisabled}
          color="primary"
          type="submit"
        >
          {contactData?.contact_button}
        </CommonButton>
      </FormContainer>
      <BottomMessage>
        {isLoading
          ? contactData?.loading_message
          : isError
          ? contactData?.error_message
          : isSuccess
          ? contactData?.success_message
          : " "}
      </BottomMessage>
    </Container>
  );
};
