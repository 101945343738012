import styled from 'styled-components';

interface PriceProps {
  title: string;
  price: string;
  subtitle?: string;
}

const PriceContainer = styled.div`
  width: calc(4.375vw + 96px); /* 1920: 180, 320: 110 */
  box-sizing: border-box;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
`;

const Title = styled.div`
  width: 70%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
`;

const PriceText = styled.div`
  flex: 0.5;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
`;

const SubtitleText = styled.div`
  width: 70%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 1.25em;
  color: ${({ theme }) => theme.palette.yellowSchool};
  margin-top: 0.25em;
`;

function Price(props: PriceProps) {
  return (
    <PriceContainer {...props}>
      <Title>
        {props.title}
      </Title>
      <PriceText>
        {props.price}
      </PriceText>
      {props.subtitle && (
        <SubtitleText>
          {props.subtitle}
        </SubtitleText>
      )}
    </PriceContainer>
  );
}

export default Price;
