export enum WorkshopTypes {
  EscenasDeCine = 'escenasDeCine',
  MeditacionPractica = 'meditacionPractica',
  FinalesDeImpro = 'finalesDeImpro',
  ReConecta = 'reConecta',
  TallerParaDocentes = 'tallerParaDocentes',
  Superimproheroes = 'superimproheroes',
  Babel = 'babel',
  Improcampa = 'improcampa',
  AccionEnLaEscena = 'accionEnLaEscena',
  ProyectoCreativo = 'proyectoCreativo',
  Interpretacion = 'interpretacion',
  ImprovisateUnTema = 'improvisateUnTema',
  ClownConFran = 'clownConFran',
  NarrativaCuerpo = 'narrativaCuerpo',
  Storytelling = 'storytelling',
  Standup = 'standup',
  Improdeutsch = 'improdeutsch',
}
