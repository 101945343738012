import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Arrow } from './Arrow';
import { useLayoutScroll } from '../hooks';

interface ScrollArrowProps {
  scrollUp(): void;
}

const ScrollArrowContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: calc(3vh + 60px);
  right: calc(1vw + 4px);
  cursor: ${({ visible }) => visible ? 'pointer' : 'initial'};
  pointer-events: ${({ visible }) => visible ? 'auto' : 'none'};
  -webkit-tap-highlight-color: transparent;
`;

const ArrowStyled = styled(Arrow)<{
  visible: boolean;
  inSchoolPage?: boolean;
}>`
  width: min(75px, 15vw);
  pointer-events: ${({ visible }) => visible ? 'auto' : 'none'};
  opacity: ${({ visible }) => visible ? 1 : 0};
  transition: opacity 0.15s ease-out, transform 0.3s;
  transform: ${({ visible }) => visible ? 'translateY(-12px)' : 'translateY(0px)'} rotate(270deg);
  color: ${({ inSchoolPage }) => inSchoolPage ? '#EFEF3C' : '#9BFFED'};
`;

function ScrollArrow(props: ScrollArrowProps) {
  const scroll = useLayoutScroll();
  const location = useLocation();
  const inSchoolPage = location.pathname.includes('school');
  const visible = scroll.top > 50;

  return (
    <ScrollArrowContainer {...props} visible={visible} onClick={props.scrollUp}>
      <ArrowStyled visible={visible} inSchoolPage={inSchoolPage} />
    </ScrollArrowContainer>
  );
}

export { ScrollArrow };
