import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WorkshopTypes } from './types';
import { WishlistTypes } from '../../types';
import { Heart } from '../../components/Heart';
import { NewsletterModal } from '../../components/NewsletterModal';
import { useWishlist } from '../../hooks';

interface WorkshopProps {
  id: WorkshopTypes;
  description: string;
  title: string;
  subtitle?: string;
  image: {
    src: string;
    alt: string;
  };
}

const apiUrl = process.env.REACT_APP_API_URL;

const WorkshopContainer = styled.div`
  color: white;
  margin: 0 10px 55px 10px;

  @media (max-width: 526px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const PictureContainer = styled.div`
  width: 100%;
  border-radius: 5px;
  margin-bottom: 24px;
  overflow: hidden;
`;

const Picture = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 55%;
`;

const PictureImg = styled.img`
  position: absolute;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  top: -2px;
  left: -2px;
`;

const TitleContainer = styled.div`
  margin-bottom: 0.75em;
`;

const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.25em;
`;

const Subtitle = styled.div`
  font-family: ${({ theme }) => theme.font.quicksand};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.25em;
  color: ${({ theme }) => theme.palette.yellowSchool};
  margin-top: 0.5em;
`;

const Description = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25em;
`;

const HeartStyled = styled(Heart)`
  color: ${({ theme }) => theme.palette.yellowSchool};
  position: absolute;
  bottom: 8px;
  right: 12px;
`;

function Workshop({
  id,
  description,
  title,
  subtitle,
  image,
}: WorkshopProps) {
  const { heartProps, newsletterModalProps } = useWishlist(WishlistTypes.SchoolWorkshop, id);

  return (
    <WorkshopContainer>
      <PictureContainer>
        <Picture>
          <PictureImg src={image.src} alt={image.src} />
          <HeartStyled {...heartProps} />
        </Picture>
      </PictureContainer>
      <TitleContainer>
        <Title>
          {title}
        </Title>
        {subtitle && (
          <Subtitle>
            {subtitle}
          </Subtitle>
        )}
      </TitleContainer>
      <Description>
        {description}
      </Description>
      <NewsletterModal {...newsletterModalProps} title={title} />
    </WorkshopContainer>
  );
}

export default Workshop;
