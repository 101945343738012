import styled from "styled-components";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { InlineMenu, HamburgerMenu, EnterprisePageMenu } from "./Menu";
import logoEsImpro from "../assets/images/logoEsImpro.png";
import logoEsImproEscuela from "../assets/images/logoEsImproEscuela.png";
import logoEsImproTeams from "../assets/images/logoEsImproTeams.png";
import { useServices } from "../hooks";
import { useMemo } from "react";
import { zIndexes } from "../styles/variables";

interface HeaderProps {
  isLivestream?: boolean;
}

const HeaderContainer = styled.div<HeaderProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  width: 100%;
  height: ${({ isLivestream }) =>
    isLivestream ? "13vh" : "calc(5.375vw + 153px)"};

  background: linear-gradient(
    180deg,
    #000227 0%,
    rgba(0, 2, 39, 0.770833) 30.73%,
    rgba(0, 2, 39, 0.255013) 68.23%,
    rgba(0, 2, 39, 0.129667) 77.08%,
    rgba(0, 2, 39, 0) 98.96%
  );
`;

const TeamsHeader = styled.header`
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(0, 3, 64, 0.5) 0%,
    rgba(0, 3, 64, 0) 100%
  );
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  position: absolute;
  width: 100%;
  z-index: ${zIndexes["header"]};
`;

const Logo = styled.div<{ pathname: string; backgroundImage?: string }>`
  position: absolute;
  top: 30px;
  left: calc(1.875vw + 24px);
  width: calc(3.5vw + 50px);
  height: 80px;

  background-image: url(${({ pathname, backgroundImage }) =>
    backgroundImage ||
    (pathname.includes("school")
      ? logoEsImproEscuela
      : pathname.includes("teams")
      ? logoEsImproTeams
      : logoEsImpro)});
  background-repeat: no-repeat;
  background-size: contain;

  &:hover {
    background-image: url(${logoEsImpro});
  }
`;

const InlineMenuResponsive = styled(InlineMenu)<{ bp: number }>`
  @media (max-width: ${({ bp }) => bp}px) {
    display: none;
  }
`;

const HamburgerMenuResponsive = styled(HamburgerMenu)<{ bp: number }>`
  @media (min-width: ${({ bp }) => bp + 1}px) {
    display: none;
  }
`;

function Menu() {
  const location = useLocation();
  const noMenu = location.pathname.includes("teams");
  const forceHamburger = location.pathname.includes("live");
  const menuBreakpoint = 830;

  return noMenu ? (
    <EnterprisePageMenu />
  ) : forceHamburger ? (
    <HamburgerMenu />
  ) : (
    <>
      <InlineMenuResponsive bp={menuBreakpoint} />
      <HamburgerMenuResponsive bp={menuBreakpoint} />
    </>
  );
}

function Header(_: HeaderProps) {
  const location = useLocation();
  const isTeams = location.pathname.includes("teams");

  const { data } = useServices();

  const logoURL = useMemo(() => {
    return data.enterprise_page?.header[0].logo.url;
  }, [data]);

  return isTeams ? (
    <TeamsHeader>
      <Link to="/home.html">
        <Logo pathname={location.pathname} backgroundImage={logoURL} />
      </Link>
      <Menu />
    </TeamsHeader>
  ) : (
    <HeaderContainer isLivestream={location.pathname.includes("live")}>
      <Link to="/home.html">
        <Logo pathname={location.pathname} />
      </Link>
      <Menu />
    </HeaderContainer>
  );
}

export { Header };
