import type { Teamup } from '../../types';

const mockEvents: Array<Teamup.Event> = [
  {
    id: 34865927,
    name: 'ESIMPRO Escuela - Introducción a la impro (*)',
    start_time: '2021-04-20T19:00:00',
    end_time: '2021-04-20T21:30:00',
    venue: {
      is_online: true,
    },
  },
  {
    id: 35470559,
    name: 'ESIMPRO Escuela - Meditación práctica (*)',
    start_time: '2021-04-22T18:30:00',
    end_time: '2021-04-22T20:00:00',
    venue: {
      is_online: true,
    },
  },
  {
    id: 34865928,
    name: 'ESIMPRO Escuela - Introducción a la impro (*)',
    start_time: '2021-04-27T19:00:00',
    end_time: '2021-04-27T21:30:00',
    venue: {
      is_online: true,
    },
  },
  {
    id: 35470560,
    name: 'ESIMPRO Escuela - Meditación práctica (*)',
    start_time: '2021-04-29T18:30:00',
    end_time: '2021-04-29T20:00:00',
    venue: {
      is_online: true,
    },
  },
  {
    id: 36891207,
    name: 'ESIMPRO Workshop - Acción en la Escena - Pinter con Juan López-Tagle (ES) (*)',
    start_time: '2021-06-24T19:00:00',
    end_time: '2021-06-24T22:00:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 40686565,
    name: 'ESIMPRO Workshop - Monta tu proyecto creativo! (ES) (*)',
    start_time: '2021-10-29T18:30:00',
    end_time: '2021-10-29T21:30:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 40686566,
    name: 'ESIMPRO Workshop - Interpretación (ES) (*)',
    start_time: '2021-11-29T18:30:00',
    end_time: '2021-11-29T21:30:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 40686567,
    name: 'ESIMPRO Workshop - ¡Improvísate un tema! (ES) (*)',
    start_time: '2021-12-04T18:30:00',
    end_time: '2021-12-04T21:30:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 44634166,
    name: 'ESIMPRO Workshop - Taller de Clown con Fran Contreras (*)',
    start_time: '2022-02-12T11:00:00',
    end_time: '2022-02-12T13:30:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 44678384,
    name: 'ESIMPRO Workshop - La narrativa del cuerpo (ES) (*)',
    start_time: '2022-03-26T11:00:00',
    end_time: '2022-03-26T14:00:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 44678385,
    name: 'ESIMPRO Workshop - Storytelling with Lee White (EN) (*)',
    start_time: '2022-05-21T11:00:00',
    end_time: '2022-05-21T14:00:00',
    venue: {
      is_online: false,
    },
  },
  {
    id: 44678386,
    name: 'ESIMPRO Workshop - Curso de Stand-Up (ES) (*)',
    start_time: '2022-05-28T11:00:00',
    end_time: '2022-05-28T14:00:00',
    venue: {
      is_online: false,
    },
  },
];

export { mockEvents };
