import { FC, useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useServices } from '../hooks/useServices';
import { LayoutContext } from '../providers/Layout';

export interface ModalProps {
  open: boolean;
  onClose(): void;
}

const ModalContainer = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 2, 39, 0.8);
  pointer-events: ${({ open }) => open ? 'auto' : 'none'};
  opacity: ${({ open }) => open ? 1 : 0};
  transition: opacity 0.3s;

  @supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
`;

const Modal: FC<ModalProps> = props => {
  const { modalPortal } = useContext(LayoutContext);

  if (modalPortal) {
    return createPortal(
      <ModalContainer open={props.open} onClick={props.onClose}>
        {props.children}
      </ModalContainer>
    , modalPortal);
  }
  return null;
}

export { Modal };
