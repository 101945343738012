import styled from 'styled-components';

interface WhatIsImprovProps {
}

const WhatIsImprovContainer = styled.div`
  margin-bottom: 8em;
`;

const Title = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: white;
  margin-bottom: 1em;
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.25em;
  text-align: center;
  color: white;
  max-width: 30em;
`;

const Accent = styled.span`
  font-weight: 700;
  color: #9BFFED;
`;

function WhatIsImprov(_: WhatIsImprovProps) {
  return (
    <WhatIsImprovContainer>
      <Title>
        ¿Qué es la impro?
      </Title>
      <Description>
        La improvisación es una disciplina del teatro, <Accent>sin guión</Accent>. Todo se crea y nace en el instante en que se está actuando, creando <Accent>historias irrepetibles</Accent>. La improvisación requiere técnica, por eso siempre estamos entrenando y en constante aprendizaje.
      </Description>
    </WhatIsImprovContainer>
  );
}

export { WhatIsImprov };
