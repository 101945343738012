import styled from 'styled-components';

enum Color {
  Blue = 'blue',
  Orange = 'orange'
}

interface ButtonProps {
  color?: Color;
  disabled?: boolean;
}

const cssProps = {
  backgroundColor: {
    [Color.Blue]: '#9BFEED',
    [Color.Orange]: '#FD5D27',
  },
  color: {
    [Color.Blue]: '#000227',
    [Color.Orange]: '#FFFFFF',
  }
};

const ButtonBase = styled.button<ButtonProps>`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;

  font-family: League Spartan;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 1.25em;
  text-align: center;
  padding: 0.5em 1.75em 0.7em 1.75em;

  background-color: ${({ color = Color.Orange }) => cssProps.backgroundColor[color]};
  color: ${({ color = Color.Orange }) => cssProps.color[color]};

  &:hover {
    cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};
  }

  opacity: ${({ disabled }) => disabled ? 0.6 : 1};
  transition: opacity 0.3s;
`;

const Button = ButtonBase;

export { Button, Color };
