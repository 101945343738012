import { useServices } from "../../../hooks";
import { useMemo } from "react";
import styled from "styled-components";
import { EventCard } from "./EventCard";

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: calc(2.5vw + 40px); /* 992: 80, 320: 40 */
  width: 100%;
`;

export const EventCardsContainer = () => {
  const { data } = useServices();

  const cards = useMemo(() => {
    return data.event_service;
  }, [data]);

  return (
    <Container>
      {cards?.map((card) => {
        return (
          <li key={card.id}>
            <EventCard
              title={card.title}
              subtitle={card.subtitle}
              description={card.description}
              imageURL={card.image.url}
              imageAlt={card.image.alt}
            />
          </li>
        );
      })}
    </Container>
  );
};
