import styled from "styled-components";
import { PrismicMediaKind } from "../../../types";
import { easeOutCubic } from "../../../components/utils";
import { useLayoutScroll } from "../../../hooks";
import { useRef } from "react";

interface IntroMediaProps {
  src: string;
  kind: PrismicMediaKind | undefined;
}

const Container = styled.div`
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100vw;
`;

const Video = styled.video`
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100vw;
`;

const Image = styled.img`
  height: 100vh;
  object-fit: cover;
  position: absolute;
  width: 100vw;
`;

const BottomShadow = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 2, 39, 0) 0%,
    rgba(0, 2, 39, 0) 91%,
    rgba(0, 2, 39, 0.4374124649859944) 97%,
    rgba(0, 2, 39, 0.8827906162464986) 100%
  );
  height: 103vh;
  object-fit: cover;
  position: absolute;
  width: 100vw;
`;

export function IntroMedia({ src, kind }: IntroMediaProps) {
  const scroll = useLayoutScroll();
  const ref = useRef<HTMLDivElement>(null);

  const containerOpacity = Math.max(
    0,
    1 - easeOutCubic(scroll.top / (ref.current?.clientHeight || 1000))
  );

  return (
    <Container ref={ref} style={{ opacity: Math.pow(containerOpacity, 2) }}>
      {kind === "image" ? (
        <Image src={src} />
      ) : kind === "all" ? (
        <Video muted loop autoPlay src={src} />
      ) : (
        <></>
      )}
      <BottomShadow />
    </Container>
  );
}
