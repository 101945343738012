import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";

interface EventCardProps {
  imageURL: string;
  imageAlt: string;
  title: string;
  subtitle: string;
  description: string;
}

const EventContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 7%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 769px) and (max-width: 992px) {
    gap: 3.5%;
  }
`;

const Image = styled.img`
  border-radius: 22px;
  min-width: calc(93% / 2);
  height: calc(100% + 4px);
  width:calc(100% + 4px);
  position: relative;
  top: -2px;
  left: -2px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  color: ${colors.secondaryText};
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
`;

const Title = styled.h3`
  color: ${colors.secondaryComponent};
  margin: 5px 0;
  ${typographies.eventTitle}

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.eventTitleLittle};
  }
`;

const Subtitle = styled.p`
  color: ${colors.mainText};
  ${typographies.commonTextBold}

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.commonTextBoldLittle};
  }
`;

const Description = styled.p`
  color: ${colors.mainText};
  ${typographies.commonText};
  
  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.commonTextLittle};
  }
`;
export const EventCard: FC<EventCardProps> = ({
  imageURL,
  imageAlt,
  title,
  subtitle,
  description,
}) => {
  return (
    <EventContainer>
      {imageURL && <Image src={imageURL} alt={imageAlt} />}
      <TextContainer>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
      </TextContainer>
    </EventContainer>
  );
};
