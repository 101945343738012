import { useEffect, useState } from 'react';
import YoutubePlayer from 'react-youtube';
import styled, { keyframes } from 'styled-components';
import Head from './Head';
import {
  getLiveshowContext,
  useLiveshowContext,
} from './context';
import { getYoutubeVideoId } from './utils';
import { messageTypes } from './services/liveshowApi';
import { useWindowSize } from './hooks/useWindowSize';

enum Layout {
  General = 'general',
  PromoUp = 'promoUp',
  PromoDown = 'promoDown'
}

function eq<T>(a: T) {
  return (b: T) => a === b;
}
const parseLayout = (layout: string): Layout =>
  Object.values(Layout).find(eq(layout)) as Layout ||
  Layout.General;

const LiveRoot = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Player = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & iframe {
    border-radius: 4px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const coordinates = {
  [Layout.General]: [
    [147, 96],
    [644, 96],
    [147, 360],
    [644, 360]
  ],
  [Layout.PromoUp]: [
    [56, 96],
    [789, 96],
    [147, 360],
    [644, 360]
  ],
  [Layout.PromoDown]: [
    [147, 96],
    [644, 96],
    [56, 360],
    [789, 360]
  ],
};

const paths = {
  [Layout.General]: [
    `
      M 2 2
      h 488
      v 126
      a 139 139 0 0 0 -135 130
      h-353
      Z
    `,
    `
      M 2 2
      h 488
      v 256
      h-355
      a 139 139 0 0 0 -133 -131
      Z
    `,
    `
      M 2 2
      h 353
      a 139 139 0 0 0 137 131
      v 126
      h-490
      Z
    `,
    `
      M 135 2
      h 355
      v 257
      h-486
      v-126
      a 139 139 0 0 0 131 -126
      Z
    `,
  ],
  [Layout.PromoUp]: [
    `
      M 2 2
      h 432
      v 256
      h-432
      Z
    `,
    `
      M 2 2
      h 440
      v 256
      h-440
      Z
    `,
    `
      M 2 2
      h 430
      a 135 135 0 0 0 60 256
      h-490
      Z
    `,
    `
      M 60 2
      h 430
      v 256
      h-490
      a 135 135 0 0 0 60 -256
      Z
    `,
  ],
  [Layout.PromoDown]: [
    `
      M 2 6
      h 490
      a 135 135 0 0 0 -60 252
      h-430
      Z
    `,
    `
      M 2 6
      h 490
      v 252
      h-430
      a 135 135 0 0 0 -60 -252
      Z
    `,
    `
      M 2 2
      h 432
      v 256
      h-432
      Z
    `,
    `
      M 2 2
      h 440
      v 256
      h-440
      Z
    `,
  ],
};

const textPosition = {
  [Layout.General]: [-20, 20, -20, 20],
  [Layout.PromoUp]: [-30, -20, -55, 55],
  [Layout.PromoDown]: [-55, 55, -30, -30],
};

const VoteButton = styled.div<{
  i: number,
  audienceVoting: boolean;
  voted: boolean;
  notVoted: boolean;
  color: string;
  layout: Layout;
}>`
  position: absolute;
  width: 494px;
  height: 261px;
  left: ${({ i, layout }) => 100 * coordinates[layout][i][0] / 1280}%;
  top: ${({ i, layout }) => 100 * coordinates[layout][i][1] / 720}%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.2);
  opacity: 0;

  color: ${({ color }) => color};
  ${({ audienceVoting }) => audienceVoting ? 'transform: scale(1); opacity: 1;' : ''}
  ${({ notVoted }) => notVoted ? 'transition: transform 1s, opacity 1s; transform: scale(0); opacity: 0;' : ''}

  &:hover {
    cursor: pointer;
  }
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const VoteButtonContour = styled.svg<{
  voted?: boolean;
}>`
  position: absolute;
  & > path {
    transition: stroke-dasharray 0.5s cubic-bezier(0.7, 0, 0.84, 0);
    stroke-dasharray: ${({ voted }) => voted ? 1500 : 50};
    stroke-dashoffset: 50;
    animation: ${dash} 1s infinite alternate ease-in-out;
    ${({ voted }) => voted ? 'animation: none' : ''};
  }
`;

const VoteSquareText = styled.p<{
  i: number;
  color: string;
  layout: Layout;
  voted?: boolean;
}>`
  position: relative;
  left: ${({ i, layout }) => textPosition[layout][i]}px;
  cursor: default;
  color: #000000c7;
  font-weight: 600;
  padding: 0 1em;
  line-height: 1.7em;
  border-radius: 4px;
  z-index: 1;
  text-align: center;
  transition: background-color 0.3s, transform 0.3s;
  background-color: ${({ voted, color }) => `${color}${voted ? 'FF' : 'A0'}`};
  ${({ voted }) => voted ? 'transform: scale(1.2);' : 'transform: scale(1.0);'}

  ${VoteButton}:hover & {
    transform: scale(1.2);
  }
`;

const videoSizes = [
  { width: 1280, height: 720 },
  { width: 960, height: 540 },
  { width: 640, height: 360 },
  { width: 480, height: 270 },
  { width: 320, height: 180 },
];

interface Player {
  color: string;
  name: string;
  role: string;
}

const sortPlayers = (a: Player, b: Player) => {
  const roles = ['player', 'idle'];
  const matchA = a.role.match(/(player|idle)([0-9]+)/) || [];
  const matchB = b.role.match(/(player|idle)([0-9]+)/) || [];
  const roleAIndex = roles.indexOf(matchA[1]);
  const roleBIndex = roles.indexOf(matchB[1]);

  return roleAIndex !== roleBIndex
    ? roleAIndex - roleBIndex
    : parseInt(matchA[2], 10) - parseInt(matchB[2], 10);
};

function Live({ scale, videoId, videoSize }: {
  scale: number;
  videoId: string;
  videoSize: {
    width: number;
    height: number;
  }
}) {
  const {
    hooks: { useLiveshowApi },
    services: { liveshowApi }
  } = useLiveshowContext() as any;
  const [audienceVoting] = useLiveshowApi(
    messageTypes.AudienceVoting,
    { players: [], voting: false, layout: Layout.General },
  );

  const [voted, setVoted] = useState(null as number | null);

  const fontSize = 8 + 16 * videoSize.width / 1280;

  const votePlayer = (player: Player, i: number) => () => {
    setVoted(i);
    liveshowApi.sendMessage([
      messageTypes.Player1Vote,
      messageTypes.Player2Vote,
      messageTypes.Player3Vote,
      messageTypes.Player4Vote,
    ][audienceVoting.players.indexOf(player)]);
  };

  useEffect(() => {
    if (!audienceVoting.voting) {
      setVoted(null);
    }
  }, [audienceVoting]);

  const layout = parseLayout(audienceVoting.layout);

  return (
    <LiveRoot style={{ transform: `scale(${scale})` }}>
      <Head />
      <Player>
        <YoutubePlayer
          videoId={videoId}
          opts={{
            width: videoSize.width.toString(),
            height: videoSize.height.toString(),
            playerVars: {
              autoplay: 1,
              controls: 0,
              disablekb: 1,
              fs: 0,
              modestbranding: 1,
              showinfo: 0,
              rel: 0,
              mute: 0,
              playsinline: 1,
            },
          }}
        />
      </Player>
      <Overlay>
        <div style={{
          position: 'relative',
          height: videoSize.height,
          width: videoSize.width
        }}>
          {[...audienceVoting.players].sort(sortPlayers).map((player: Player, i: number) => (
            <VoteButton
              key={i}
              style={{
                transition: 'transform 0.3s, opacity 0.3s',
                width: 494 * videoSize.width / 1280,
                height: 261 * videoSize.height / 720
              }}
              color={player.color}
              i={i}
              audienceVoting={audienceVoting.voting}
              voted={voted === i}
              notVoted={voted !== i && voted !== null}
              layout={layout}
              onClick={votePlayer(player, i)}
            >
              <VoteButtonContour
                version="1.1"
                baseProfile="full"
                viewBox="0 0 494 261"
                xmlns="http://www.w3.org/2000/svg"
                voted={voted === i}
              >
                <path
                  d={paths[layout][i]}
                  fill="currentColor"
                  fillOpacity="0.5"
                  stroke="currentColor"
                  strokeWidth="4px"
                  strokeOpacity="0.8"
                  pointerEvents="visiblePainted"
                />
              </VoteButtonContour>
              <VoteSquareText
                style={{ fontSize }}
                i={i}
                voted={voted === i}
                color={player.color}
                layout={layout}
              >
                {voted === i
                  ? `¡${player.name}!`
                  : `Voto a ${player.name}`
                }
              </VoteSquareText>
            </VoteButton>
          ))}
        </div>
      </Overlay>
    </LiveRoot>
  );
}

function LiveConnected() {
  const [liveshowContext] = useState(getLiveshowContext());
  const {
    react: { Provider },
    ...context
  } = liveshowContext;

  const {
    hooks: { useLiveshowApi },
  } = context;

  const [streamSource] = useLiveshowApi(messageTypes.StreamSource);
  const videoId = streamSource
    ? getYoutubeVideoId(streamSource)
    : null;

  const windowSize = useWindowSize();
  const width = windowSize.width || 1280;
  const height = windowSize.height || 1280;

  let videoSize = videoSizes[0];
  if (width < 1700 && width >= 1350) {
    videoSize = videoSizes[1];
  } else if (width < 1350 && width >= 800) {
    videoSize = videoSizes[2];
  } else if (width < 800 && width >= 480) {
    videoSize = videoSizes[3];
  } else if (width < 480) {
    videoSize = videoSizes[4];
  }

  const scale = Math.min(width / videoSize.width, height / videoSize.height);

  return videoId ? (
    <Provider value={context}>
      <Live
        scale={scale}
        videoId={videoId}
        videoSize={videoSize}
      />
    </Provider>
  ) : <Head />;
}

export {
  Live,
  LiveConnected,
}
export default LiveConnected;
