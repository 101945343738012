import { Redirect, Route, Switch } from 'react-router-dom';
import { Home } from './pages/Home';
import { Shows } from './pages/Shows';
import { School } from './pages/School';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import Live from './pages/Live';
import { EnterprisePage } from './pages/Enterprise';
import { routes as plainRoutes } from './routes-cjs';

const routeComponents = {
  '/home.html': <Home />,
  '/shows.html': <Shows />,
  '/school.html': <School />,
  '/about.html': <About />,
  '/teams.html': <EnterprisePage />,
  '/contact.html': <Contact />,
  '/live.html': <Live />,
};

const routes = plainRoutes.map(path => ({
  path,
  component: routeComponents[path as keyof typeof routeComponents],
}));

function Routes() {
  return (
    <Switch>
      {routes.map(({ path, component }) => (
        <Route key={path} exact path={path}>{component}</Route>
      ))}
      <Route><Redirect to={routes[0].path} /></Route>
    </Switch>
  );
}

export { Routes };
