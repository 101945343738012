export enum ShowFormatTypes {
  Banco = 'banco',
  Sitcom = 'sitcom',
  Estacion = 'estacion',
  Checkpoint = 'checkpoint',
  Laboratorio = 'laboratorio',
  SesionTriple = 'sesionTriple',
  QueTeCuentas = 'queTeCuentas',
  Hotel = 'hotel',
}
