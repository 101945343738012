import styled from 'styled-components';
import Teacher from './Teacher';
import gon from './gon.jpg';
import paula from './paula.jpg';
import ale from './ale.jpg';

interface TeachersProps {
}

const TeachersContainer = styled.div`
  margin-top: 80px;
  display: flex;
  align-items: flex-start;
  padding: 0 calc(24.509803921568626vw + -170.58823529411762px); /* 1920: 300, 900: 50 */
  width: 100%;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

function Teachers(_: TeachersProps) {
  return (
    <TeachersContainer>
      <Teacher
        name='Gonzalo Piñán'
        role='Profesor y director de la escuela'
        description={'Profesor y director y fundador de ESIMPROEscuela. Forma parte de la compañía ESIMPRO y co - dirige la serie web improvisada ESIMPROCine. Es actor improvisador, músico, compositor y psicólogo licenciado en Madrid. Estudió en “El Club de la Impro”, Die Gorillas, y con maestras como Paula Galimberti, Tim Orr o Inbal Lori. En España fue hizo giras internacionales conla banda de mestizaje “Quinto Parpadeo” y formó parte de la compañía “Al Tran Trán Impromusical”. Tras migrar, ha colaborado en la creación de la comunidad hispanohablante y artística en Berlín. Como músico y actor colabora en distintos proyectos, y como docente imparte clases y talleres.'}
        image={gon}
      />
      <Teacher
        name='Paula Galimberti'
        role='Profesora y coach'
        description={'Paula es actriz, directora, entrenadora e improvisadora. Estudió actuación en la escuela Cristina Rota y completó su carrera profesional con su coach Jordan Bayne. Ella ha estado trabajando como actriz desde 1996. Co-fundadora de su propia compañía de improvisación "Jamming" hace 17 años. También podemos verla trabajando en cine y televisión. Trabajó como profesora de interpretación y de improvisación por todo el mundo. Ahora trabaja regularmente entre Berlín y Madrid y también viaja por todo el mundo. En Madrid trabaja con su empresa Jamming. Como actriz, directora y entrenadora. Y en Berlín colabora con ESimpro, es maestra en ESimproescuela y codirectora de Esimprocine.'}
        image={paula}
      />
      <Teacher
        name='Alejandro Goiri'
        role='Profesor y coach'
        description='Combina sus años de experiencia en Impro de la mano de maestros como Gonzalo Piñán, Paula Galimberti, David Razowsky, Gonzalo Rodolico o Feña Ortalli, con su experiencia corporativa internacional, con la misión de llevar la Impro a cada vez más sitios. Psicólogo con una dilatada carrera en selección y formación de talento en empresas como Amazon, Audible, BASF o Zalando. Combina psicología, multiples idiomas, recursos humanos e impro, para diseñar experiencias formativas que saquen lo mejor de cada persona, de cada equipo o de cada organización. Muy amigo de sus amigos.'
        image={ale}
      />
    </TeachersContainer>
  );
}

export default Teachers;
