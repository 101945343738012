import { useEffect, useState } from 'react';
import { messageTypes } from '../services/liveshowApi';

function getUseLiveshowApi(liveshowApi) {
  const lastMessage = {};
  const listeners = Object.values(messageTypes).reduce((result, messageType) => ({
    ...result,
    [messageType]: new Map(),
  }), {});

  function messageHandler(messageType, messageData) {
    if (messageType) {
      lastMessage[messageType] = messageData;
      listeners[messageType].forEach((listener) => listener(messageData));
    }
  }

  liveshowApi.init(messageHandler);

  return function useLiveshowApi(messageType, initValue = null) {
    const [currentMessage, setMessage] = useState(lastMessage[messageType] || initValue);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      const listener = Symbol('listener');
      listeners[messageType].set(listener, (...args) => {
        setMessage(...args);
        setLoading(false);
      });
      return () => listeners[messageType].delete(listener);
    }, [messageType]);

    function setApiState(messageData) {
      setLoading(true);
      liveshowApi.sendMessage(messageType, messageData);
    };

    return [currentMessage, setApiState, isLoading];
  };
}

export {
  getUseLiveshowApi,
}
