import { useEffect, useState } from 'react';
import getDateFingerprint from './getDateFingerprint';
import { BrowserFingerprint } from './browser';
import { ServerFingerprint } from './server';
import { Context as RenderContext, Environment, Storage } from '../../types';

function useFingerprint(environment: Environment) {
  const [fingerprint, setFingerprint] = useState(getDateFingerprint('stateInit'));

  // Load fingerprint
  useEffect(() => {
    const loader = new ({
      [Environment.Static]: ServerFingerprint,
      [Environment.Browser]: BrowserFingerprint,
    }[environment])(setFingerprint);
    loader.load();
    return loader.cancel;
  }, []);

  return fingerprint;
}

export default useFingerprint;
