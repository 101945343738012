export const colors = {
  mainBackground: "#000227",
  mainComponent: "#FD5D27",
  mainText: "#FFFFFF",
  secondaryText: "#D6E0FF",
  secondaryComponent: "#9BFFED",
  tertiaryComponent: "#66CFCB",
};

const zIndexesArray = ["content", "overlay", "header"];

export const zIndexes = zIndexesArray.reduce(
  (prevValue, currentValue, index) => {
    prevValue[currentValue] = index + 1;
    return prevValue;
  },
  {} as Record<string, number>
);
