import styled from 'styled-components';
import { getTeamupEvents } from './utils';
import { ScrollableContainer } from '../../components/ScrollableContainer';
import { useServices } from '../../hooks';

import type { FC } from 'react';

interface UpcomingEventsProps {
}

function UpcomingEvents(props: UpcomingEventsProps) {
  const { data } = useServices();

  const events = getTeamupEvents(data);

  return (
    <ScrollableContainer {...props} events={events} />
  );
}

export { UpcomingEvents };
