import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ScrollButton } from '../../components/ScrollButton';
import { Content as CollapsibleButtonContent } from '../../components/CollapsibleButton';
import { CircularPicture } from '../../components/CircularPicture';

import laptopBerlin from './laptopBerlin.jpg';

interface PromoTeamsProps {
}

const PromoTeamsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 630px) {
    flex-direction: column-reverse;
  }

  padding-right: calc(9.375vw + -60px);
  margin-top: 20vh;
`;

const ImageContainer = styled.div`
  flex: 0.75;
  max-width: 680px;
`;

const Image = styled.img`
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em;
`;

const TextContainer = styled.div`
  align-self: center;
`;

const Title = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.375vw + 22.8px); /* 1920: 30, 320: 24 */
  line-height: 1.25em;
  color: white;
  margin-bottom: 1em;
  max-width: 16em;
`;

const Subtitle = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(0.1875vw + 14.4px); /* 1920: 18, 320: 15 */
  line-height: 1.25em;
  color: #FD5D27;
  margin-bottom: 2em;
  max-width: 24em;
`;

const LinkStyled = styled(Link)`
  align-self: center;
  margin-top: 2em;
`;

const SignupButton = styled(ScrollButton)`
  font-size: 18px;
  color: white;
  font-family: League Spartan;
  ${CollapsibleButtonContent} {
    transform: translateY(-2px);
  };
`;

function PromoTeams(_: PromoTeamsProps) {
  return (
    <PromoTeamsContainer>
      <Content>
        <TextContainer>
          <Title>
            Imagine applying improv to your work group
          </Title>
          <Subtitle>
            ESIMPRO Teams will help your company to grow better, create a good working environment and implement new strategies on a daily basis
          </Subtitle>
        </TextContainer>
        <LinkStyled to='/teams.html' target='_blank'>
          <SignupButton
            borderColor='#FD5D27'
            fillColor='#FD5D27'
            from={0.99}
            to={0.95}
            onClick={() => {}}
          >
            discover more
          </SignupButton>
        </LinkStyled>
      </Content>
      <ImageContainer>
        <CircularPicture src={laptopBerlin} alt='Laptop with Berlin skyline' />
      </ImageContainer>
    </PromoTeamsContainer>
  );
}

export { PromoTeams };
