import { getMobileMediaQuery } from "../utils/mediaQuery";
import { colors } from "./variables";

export const typographies = {
  button: `
            color: ${colors.mainText};
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 15px;`,

  pageTitle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 140%;
            text-align: center;`,

  pageTitleLittle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 140%;
            text-align: center;`,

  sectionTitle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 140%;`,

  sectionTitleLittle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: auto;`,

  sectionSubtitle: `        
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 17px;`,

  sectionSubtitleLittle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;`,

  cardTitle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;`,

  commonTextBold: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 140%;`,

  commonTextBoldLittle: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;`,

  commonText: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;`,

  commonTextLittle: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;`,

  eventTitle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 140%;`,

  eventTitleLittle: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;`,

  facilitatiorFrontText: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;`,

  facilitatiorBackText: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 140%;`,

  testimony: `
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 140%;
            ${getMobileMediaQuery(`
              font-size: 20px;
            `)}
            `,
  contactFormMessage: `
            font-family: 'League Spartan';
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 36px;
            text-align: center;
            ${getMobileMediaQuery(`
              font-size: 16px;
              line-height: 1.5;
            `)}`,
};
