import { FC, useState } from "react";
import styled from "styled-components";
import { typographies } from "../../../styles/typographies";
import { colors } from "../../../styles/variables";
import { UpArrowSvg } from "../assets/UpArrow.svg";
import { DownArrowSvg } from "../assets/DownArrow.svg";

interface TrainingCardProps {
  title: string;
  shortDescription: string;
  longDescription: string;
  svgUrl: string;
  svgAlt: string;
  isImportant: boolean;
}

const Container = styled.div<{ isImportant: boolean }>`
  align-items: center;
  ${({ isImportant }) =>
    isImportant ? `border: solid 2px ${colors.mainText}15;` : ""}
  background: ${colors.mainText}${({ isImportant }) =>
    isImportant ? "00" : "15"};
  border-radius: 30px;
  display: flex;
  gap: 45px;
  padding: 45px 45px;

  @media (max-width: 768px) {
    padding: 22.5px 22.5px;
    flex-direction: column;
  }
`;

const TextContainer = styled.div`
  color: ${colors.mainText};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 5px;
`;

const Title = styled.p`
  ${typographies.cardTitle}
`;

const Description = styled.p`
  ${typographies.commonText};

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.commonTextLittle};
  }
`;

const ExpandButton = styled.button`
  align-items: center;
  background: ${colors.mainText}10;
  border-radius: 50%;
  color: ${colors.secondaryComponent};
  cursor: pointer;
  display: flex;
  height: 46px;
  justify-content: center;
  min-height: 46px;
  min-width: 46px;
  width: 46px;
`;

export const TrainingCard: FC<TrainingCardProps> = ({
  title,
  shortDescription,
  longDescription,
  svgUrl,
  svgAlt,
  isImportant,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Container isImportant={isImportant}>
      <img src={svgUrl} alt={svgAlt} width={92} height={92} />
      <TextContainer>
        <Title>{title}</Title>
        <Description>{shortDescription}</Description>
        {isExpanded && <Description>{longDescription}</Description>}
      </TextContainer>
      <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <UpArrowSvg /> : <DownArrowSvg />}
      </ExpandButton>
    </Container>
  );
};
