import { Storage } from '../types';

const getStorageKey = (fingerprint: string, key: string) => `esimpro.${fingerprint}.${key}`;

const getStoredValue = (storage: Storage) => <T>(key: string) => {
  function get(): T {
    const persisted = storage.getItem(key);
    return persisted && JSON.parse(persisted);
  }

  function set<T>(value: T) {
    storage.setItem(key, JSON.stringify(value));
  }

  function clear() {
    storage.removeItem(key);
  }

  return {
    get,
    set,
    clear
  };
};

export {
  getStorageKey,
  getStoredValue,
};
