import { useContext } from 'react';
import styled from 'styled-components';
import Head from './Head';
import { ButtonTickets } from './ButtonTickets';
import ShowFormats from './ShowFormats';
import ContactUs from './ContactUs';
import { SectionHeader } from '../../components/SectionHeader';
import { Hero, HeroImage } from '../../components/Hero';
import { Footer } from '../../components/Footer';

import heart from './assets/heart.svg';
import hero from './assets/hero.jpg';
// @ts-ignore
import heroPlaceholder from './assets/heroPlaceholder.jpg?inline';

interface ShowsProps {
}

const ShowsContainer = styled.div`
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding: calc(-1.875vw + 36px); /* 1920: 0, 320: 30 */
  padding-bottom: 200px;
  z-index: 0;
`;

const WishlistExplainer = styled.div`
  color: #9BFFED;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  padding: 0px calc(8.75vw + -28px); /* 1920: 140, 320: 0 */

  @media (max-width: 630px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Heart = styled.img`
  color: white;
  margin: 0 1em 0 0;

  @media (max-width: 630px) {
    margin: 0 0 1em 0;
  }
`;

function Shows(_: ShowsProps) {
  return (
    <ShowsContainer>
      <Head />
      <Hero title='¿Qué Hacemos en ESIMPRO?'>
        <HeroImage
          src={hero}
          alt='Escena de Sitcom'
          opacity={0.7}
          placeholder={heroPlaceholder}
        />
      </Hero>
      <Content>
        <SectionHeader
          title='Cartelera'
          description='Ven y diviértete con nuestros shows'
          separator
        />
        <ButtonTickets />
        <SectionHeader
          title='Nuestros shows'
          description='Descubre todo lo que hacemos'
          separator
        />
        <WishlistExplainer>
          <Heart src={heart} alt='Icono de corazón' />
          Nos gusta saber qué formatos os gusta ver. Toca sus corazones y haremos todo lo posible por programarlos.
        </WishlistExplainer>
        <ShowFormats />
        <SectionHeader
          title='Eventos'
          description='Bodas, bautizos y comuniones. No, en serio, puedes contratarnos para lo que quieras'
        />
        <ContactUs />
        <Footer />
      </Content>
    </ShowsContainer>
  );
}

export { Shows };
