import styled from 'styled-components';

interface ButtonTicketsProps {
  dark?: boolean;
}

const linkUrl = 'https://buytickets.at/esimpro';

const ButtonTicketsContainer = styled.a<ButtonTicketsProps>`
  background-color: ${({ theme }) => theme.palette.orange};
  color: white;

  height: 3em;
  width: 11.25em;
  left: 50%;
  position: relative;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 30px;
  box-shadow: 0px 2.88789px 2.88789px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  margin: 1.5em 0 4em 0;
`;

const Text = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.25em;
  text-align: center;
`;

function ButtonTickets(props: ButtonTicketsProps) {
  return (
    <ButtonTicketsContainer href={linkUrl} target='_blank' {...props}>
      <Text>
        Entradas
      </Text>
    </ButtonTicketsContainer>
  );
}

export { ButtonTickets };
