import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <title>Mándanos un mensaje | ESIMPRO</title>
      <meta name="description" content="Escríbenos. Ponte en contacto con la escuela y con la compañía si tienes preguntas o dudas." />
    </Helmet>
  );
}

export { Head };
