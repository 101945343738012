import { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

interface HeartProps {
  filled?: boolean;
  onClick?(): void;
}

const HeartContainer = styled.svg`
  color: white;
  width: 22px;
  height: 19px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
`;

const Outline = styled.path<{ filled?: boolean }>`
  stroke: ${({ filled }) => filled ? '#F51D00' : 'currentColor'};
  transition: stroke 1.0s ease;
  pointer-events: none;
`;

interface FillOrigin {
  left: number;
  top: number;
}

const Fill = styled.circle<{ filled?: boolean; fillOrigin: FillOrigin }>`
  transform-origin: ${({ fillOrigin }) => `${fillOrigin.left}px ${fillOrigin.top}px`};
  transform: scale(${({ filled }) => filled ? 1.05 : 0 });
  transition: transform 0.5s;
  pointer-events: none;
`;

let id = 0;
function Heart(props: HeartProps) {
  const heartId = `heart-${id++}`;
  const [fillOrigin, setFillOrigin] = useState<FillOrigin>({ left: 11, top: 9.5 });

  function handleClick(event: MouseEvent<SVGSVGElement>) {
    const rect = (event.target as SVGSVGElement).getBoundingClientRect();
    const left = event.clientX - rect.left - 0.5;
    const top = event.clientY - rect.top - 0.5;
    setFillOrigin({ left, top });

    if (typeof props.onClick === 'function') {
      props.onClick();
    }
  }

  return (
    <HeartContainer {...props} viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleClick}>
      <Outline filled={props.filled} id={heartId} d="M9.83811 17.0835C10.3108 17.4004 10.7087 17.6529 10.9966 17.8308C11.2848 17.6504 11.6837 17.3943 12.1578 17.0731C13.1672 16.3892 14.5082 15.4165 15.8445 14.2568C17.1853 13.0932 18.4937 11.7651 19.4601 10.3746C20.433 8.97468 21 7.59685 21 6.31285C21 3.32772 18.7189 1.00019 16.016 1.00019V1.00022L16.0078 1.00016C14.3512 0.986529 12.7784 1.86513 11.8516 3.37116L11.0049 4.74696L10.1511 3.3756C9.21661 1.8748 7.6455 0.996419 5.98788 1.00018C3.28846 1.01478 1.01501 3.33546 1 6.31499C1.00062 7.62434 1.56989 9.0139 2.5407 10.4149C3.50634 11.8084 4.81374 13.1329 6.1536 14.29C7.48907 15.4434 8.82928 16.4071 9.83811 17.0835Z" strokeWidth="2" strokeLinecap="round"/>
      <clipPath id={`${heartId}-clip`}>
        <Fill filled={props.filled} fillOrigin={fillOrigin} cx="11" cy="9.5" r="10" />
      </clipPath>
      <use clipPath={`url(#${heartId}-clip)`} href={`#${heartId}`} fill="red" />
    </HeartContainer>
  );
}

export { Heart };
