import { Helmet } from 'react-helmet-async';
import logoEsImpro from '../../assets/images/logoEsImpro.png';

function Head() {
  return (
    <Helmet>
      <title>Shows online | ESIMPRO </title>
      <meta name="description" content="Entra en nuestra web y disfruta de nuestros shows online. Mira nuestro show interactivo, El Cangrejo Inmortal" />

      <meta property="og:type" content="video.other" />
      <meta property="og:url" content="https://www.berlinesimpro.com/live.html" />
      <meta property="og:title" content="El Cangrejo Inmortal | ESIMPRO" />
      <meta property="og:description" content="El Cangrejo Inmortal es un concurso improvisado donde los participantes demuestran sus reflejos mentales para responder a preguntas de lo mas relevantes para el curso de la historia" />

      <meta property="og:video:actor:profile:first_name" content="Gonzalo" />
      <meta property="og:video:actor:profile:last_name" content="Piñán" />
      <meta property="og:video:actor:profile:gender" content="male" />
      <meta property="og:video:actor:role" content="improviser" />

      <meta property="og:video:actor:profile:first_name" content="Lolo" />
      <meta property="og:video:actor:profile:last_name" content="Diego" />
      <meta property="og:video:actor:profile:gender" content="male" />
      <meta property="og:video:actor:role" content="improviser" />

      <meta property="og:video:actor:profile:first_name" content="Guacimara" />
      <meta property="og:video:actor:profile:last_name" content="Piñán" />
      <meta property="og:video:actor:profile:gender" content="female" />
      <meta property="og:video:actor:role" content="improviser" />

      <meta property="og:video:actor:profile:first_name" content="Raquel" />
      <meta property="og:video:actor:profile:last_name" content="Piñán" />
      <meta property="og:video:actor:profile:gender" content="female" />
      <meta property="og:video:actor:role" content="improviser" />

      <meta property="twitter:title" content="El Cangrejo Inmortal | ESIMPRO" />
      <meta property="twitter:description" content="El Cangrejo Inmortal es un concurso improvisado donde los participantes demuestran sus reflejos mentales para responder a preguntas de lo mas relevantes para el curso de la historia" />
    </Helmet>
  );
}

export default Head;
