import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HamburgerMenuIcon } from './HamburgerMenuIcon';
import { menuItems } from './const';
import { CookieManager } from '../CookieManager';
import teamUp from './teamUp.png';

interface ButtonTeamupProps {
  dark?: boolean;
}

const linkUrl = 'https://goteamup.com/p/3640530-esimpro-escuela/';

const ButtonTeamupContainer = styled.a<ButtonTeamupProps>`
  background-color: ${({ theme }) => theme.palette.orange};
  color: white;

  display: flex;
  align-items: center;
  padding: 3px;
  border-radius: 30px;
  box-shadow: 0px 2.88789px 2.88789px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const Text = styled.div`
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 1.25em;
  text-align: center;

  width: 100px;
  margin: 0 1.5em 0 2em;
`;

const LogoContainer = styled.div`
  width: 44px;
  height: 44px;
  background-color: white;
  border-radius: 50%;
  position: relative;

  & > img {
    position: absolute;
    top: 11px;
    left: 11px;
  }
`;

function ButtonTeamup(props: ButtonTeamupProps) {
  return (
    <ButtonTeamupContainer href={linkUrl} target='_blank' {...props}>
      <Text>
        Acceso al campus TeamUp
      </Text>
      <LogoContainer>
        <img src={teamUp} alt='TeamUp logo' />
      </LogoContainer>
    </ButtonTeamupContainer>
  );
}

export { ButtonTeamup };
