import { createElement } from 'react';
import { hydrate, render } from 'react-dom';
import { Environment } from './types';
import { App } from './App';

if (document.body) {
  const div = document.getElementById('root');
  const renderFn = div && div.hasChildNodes() ? hydrate : render;
  renderFn(
    createElement(App, { environment: Environment.Browser }),
    document.getElementById('root')
  );
}
