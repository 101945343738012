import { FC, useEffect, useLayoutEffect, useState, useRef } from 'react';
import styled from 'styled-components';

interface FormFieldProps {
  label: string;
  value: any;
  textArea?: boolean;
}

const FormFieldContainer = styled.div`
  flex: 1;
`;

const Field = styled.div<{ openWidth: number; textArea?: boolean; }>`
  border: 1px solid #9BFFED;
  border-radius: 20px;
  margin-right: 4px;
  display: flex;
  align-items: center;
  padding: 0.75em 1em;
  position: relative;
  ${({ textArea }) => textArea
    ? 'align-items: flex-start;'
    : ''
  }

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: 20px;
    width: ${({ openWidth }) => openWidth}px;
    height: 5px;
    transform-origin: left;
    transform: scale(${({ openWidth }) => openWidth > 0 ? 1 : 0});
    background-color: ${({ theme }) => theme.palette.background};
    transition: transform 0.2s;
  }
`;

const Label = styled.label<{ active?: boolean }>`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #9BFFED;
  opacity: 0.5;

  position: absolute;
  pointer-events: none;

  ${({ active }) => active
    ? 'transform: translate(8px, -21px) scale(0.85);'
    : ''
  }
  transition: transform 0.2s;
`;

const FormField: FC<FormFieldProps> = ({
  children,
  label,
  textArea,
  value,
  ...propsRest
}) => {
  const [openWidth, setOpenWidth] = useState(0);
  const labelRef = useRef<HTMLLabelElement>(null);
  const labelWidth = useRef(0);

  const open = () => setOpenWidth(labelWidth.current + 10);
  const close = () => { if (!value) setOpenWidth(0); };

  useEffect(() => {
    if (value && openWidth === 0) open();
  }, [value, openWidth]);

  useLayoutEffect(() => {
    if (!labelWidth.current) {
      labelWidth.current = labelRef.current?.getBoundingClientRect().width || 60;
    }
  }, [labelWidth.current]);

  return (
    <FormFieldContainer onFocus={open} onBlur={close} {...propsRest}>
      <Field openWidth={openWidth} textArea={textArea}>
        <Label active={openWidth > 0} ref={labelRef}>
          {label}
        </Label>
        {children}
      </Field>
    </FormFieldContainer>
  );
};

export { FormField };
