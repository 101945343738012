import styled from 'styled-components';
import Price from './Price';

interface PricesProps {
}

const PriceStyled = styled(Price)``;

const PricesContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 300px;
  flex-wrap: wrap;
  margin-bottom: 100px;
  @media (min-width: 630px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    & > ${PriceStyled}:nth-child(-n + 4) {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 100%;
        background: linear-gradient(180deg, rgba(155, 255, 237, 0) 1.56%, rgba(155, 255, 237, 0.3) 48.96%, rgba(155, 255, 237, 0) 100%);
      }
    }
  }
`;

function Prices(_: PricesProps) {
  return (
    <PricesContainer>
      <PriceStyled
        title='1 clase'
        price='25€'
      />
      <PriceStyled
        title='mensual'
        price='75€'
      />
      <PriceStyled
        title='4 clases'
        price='90€'
        subtitle='Válido 2 meses'
      />
      <PriceStyled
        title='trimestral'
        price='210€'
      />
    </PricesContainer>
  );
}

export default Prices;
