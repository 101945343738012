import styled from 'styled-components';
import { Dots } from './Dots';
import { Person } from './Person';
import { CircularPicture } from '../../components/CircularPicture';
import { Facebook } from '../../components/Facebook';
import { Instagram } from '../../components/Instagram';

import oldLogo from './assets/oldLogo.png';
import currentLogo from './assets/currentLogo.png';

interface TimelineProps {
}

const TimelineContainer = styled.div`
  position: relative;
`;

const Year = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  font-size: calc(3.125vw + 20px);
  text-align: center;

  color: #9BFFED;
`;

const P2 = styled.p`
  font-weight: 400;
  font-size: calc(0.25vw + 15.2px);
  width: calc(12.5vw + 160px);

  color: #FFFFFF;

  margin-top: 2em;
`;

const SocialsContainer = styled.div`
  margin-top: 1.5em;
`;
const SocialLink = styled.a`
  color: white;
`;
const InstagramStyled = styled(Instagram)`
  width: 50px;
  height: 50px;
  margin-right: 1.5em;
`;
const FacebookStyled = styled(Facebook)`
  width: 50px;
  height: 50px;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const SuperBold = styled.span`
  font-weight: 700;
  font-size: 22px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;
const Block1 = styled(Block)`
  position: relative;

  @media (min-width: 631px) {
    top: 4em;
    left: -17vw;
  }
`;
const Picture1 = styled.img`
  position: relative;
  width: calc(12.625vw + 155.6px);

  @media (min-width: 631px) {
    top: -10em;
    right: -12em;
    transform: translateY(-10px) scale(0.75);
  }
  @media (max-width: 630px) {
    margin-top: 4em;
    left: 40px;
  }
`;

const Block2 = styled(Block)`
  position: relative;

  @media (min-width: 631px) {
    top: calc(-9.154383242823895vw - 192.23584173778121px);
    right: calc(-10.861132660977502vw - 111.46625290923197px);
  }
`;
const Picture2 = styled.div`
  position: relative;
  height: calc(15.0625vw + 198.8px);
  width: calc(15.0625vw + 198.8px);

  @media (min-width: 631px) {
    left: -20vw;
  }
  @media (max-width: 630px) {
    margin-top: 10em;
  }
`;
const Picture2Img = styled.img`
  width: 100%;
  height: 100%;
  transform: translateY(-20px) scale(0.75);
`;

const Block3 = styled(Block)`
  position: relative;
  margin: 11em 0 calc(3.75vw + 88px) 0;
`;

const PersonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  & > * {
    margin: calc(0.5625vw + 5.2px) calc(0.6875vw + 4.8px); // 16|7, 18|7
  }
  margin-top: 1em;
`;

function Timeline(_: TimelineProps) {
  return (
    <TimelineContainer>
      <Dots />
      <Block1>
        <Year>
          2013
        </Year>
        <P2>
          En 2013 fundamos el grupo <Bold>Berlín&nbsp;ES&nbsp;Impro</Bold>, el primer grupo de impro en español de toda Alemania, yeah! ¡Creamos Comunidad hispanohablante!
        </P2>
      </Block1>
      <Picture1 src={oldLogo} alt='First ESIMPRO logo' />
      <Picture2>
        <Picture2Img src={currentLogo} alt='Current ESIMPRO logo' />
      </Picture2>
      <Block2>
        <P2>
          En 2020 pasamos por tiempos difíciles... en lugar de rendirnos, lo aprovechamos para reinventarnos. Nos pusimos al día y actualizamos nuestra imagen como ESIMPRO.
        </P2>
      </Block2>
      <Block3>
        <Year>
          Y ahora...
        </Year>
        <P2>
          Ahora ESIMPRO es la compañía con más experiencia en el mundo artístico hispanohablante de Berlín. Seguimos inspirando a grupos y a personas cada día con un propósito claro, el de crecer y mejorar de la mano. Ven a vivir la experiencia, ven a conocernos, ¡síguenos en redes para estar al día!
        </P2>
        <SocialsContainer>
          <SocialLink href='https://instagram.com/berlin_es_impro' target='_blank'>
            <InstagramStyled />
          </SocialLink>
          <SocialLink href='https://www.facebook.com/ESIMPRO/' target='_blank'>
            <FacebookStyled />
          </SocialLink>
        </SocialsContainer>
      </Block3>
    </TimelineContainer>
  );
}

export { Timeline };
