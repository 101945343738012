import styled from 'styled-components';

interface CircularPictureProps {
  src: string;
  alt: string;
}

interface Picture {
}

const CircularPictureContainer = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  clip-path: circle(50%);
`;

const Picture = styled.img`
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  position: relative;
  top: -2px;
  left: -2px;
  border-radius: 50%;
`;

function CircularPicture(props: CircularPictureProps) {
  return (
    <CircularPictureContainer {...props}>
      <Picture src={props.src} alt={props.alt} />
    </CircularPictureContainer>
  );
}

export { CircularPicture };
