import styled from 'styled-components';

import smiley from './smiley.svg';

interface EmptyListProps {
}

const EmptyListContainer = styled.div`
  margin: calc(2.1875vw + 23px) 0 100px;
  width: 100%;
  box-sizing: border-box;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 calc(11.875vw + -38px); /* 1920: 190, 320: 0 */
`;

const EmptyListText = styled.p`
  margin-right: 1em;
  color: white;
`;

const ShowPlaceholder = styled.div`
  flex: 0 0 calc(9.375vw + 170px);
  background-color: #C4C4C4;
  border-radius: 5px;
  height: 195px;
  margin-right: 18px;
`;

const Smiley = styled.img`
`;

function EmptyList(_: EmptyListProps) {
  return (
    <EmptyListContainer>
      <Content>
        <EmptyListText>
          Ahora mismo no tenemos nada programado. Pero no te preocupes, volveremos.
        </EmptyListText>
        <Smiley src={smiley} alt='Smiley' />
      </Content>
    </EmptyListContainer>
  );
}

export { EmptyList };
