export function SeparatorSvg() {
  return (
    <svg
      width="1076"
      height="10"
      viewBox="0 0 1076 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4840_3656)">
        <path
          d="M4.75 1H1071.25"
          stroke="url(#paint0_linear_4840_3656)"
          strokeWidth="2"
          shapeRendering="crispEdges"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4840_3656"
          x="0.75"
          y="0"
          width="1074.5"
          height="10"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4840_3656"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4840_3656"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4840_3656"
          x1="1090"
          y1="4.92648"
          x2="-13"
          y2="4.92649"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9BFFED" stopOpacity="0" />
          <stop offset="0.522222" stopColor="#9BFFED" />
          <stop offset="1" stopColor="#9BFFED" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
