import { useServices } from "../../../hooks";
import { CommonButton } from "./CommonButton";

export function EnterprisePageMenu() {
  const {
    data: { enterprise_page: pageData },
  } = useServices();

  return (
    <a href="#contact">
      <CommonButton>{pageData?.header[0].button}</CommonButton>
    </a>
  );
}
