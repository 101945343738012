import { useEffect, useMemo, useState } from 'react';
import { getStorageKey, getStoredValue } from './utils';
import { useServices } from './useServices';

function usePersistedState<T>(
  initState: T,
  key: string
): [T, (value: T | ((currentState: T) => T)) => void, () => void] {
  const { fingerprint, storage } = useServices();

  const storedValue = useMemo(
    () => getStoredValue(storage)<T>(getStorageKey(fingerprint, key)),
    [fingerprint, key]
  );

  const [state, setState] = useState<T>(storedValue.get() || initState);
  useEffect(() => { setState(storedValue.get()) }, [storedValue]);

  const updateState = useMemo(
    () => (value: T | ((currentState: T) => T)) => {
      if (typeof value === 'function') {
        setState(currentState => {
          const newState = (value as Function)(currentState);
          storedValue.set(newState);
          return newState;
        });
      } else {
        setState(value);
        storedValue.set(value);
      }
    },
    [storedValue]
  );

  return [state, updateState, storedValue.clear];
}

export { usePersistedState };
