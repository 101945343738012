import { AnchorHTMLAttributes, FC, ReactNode } from "react";
import { HyperLinkRichText } from "types";

export const getHyperLinkText = (
  text: HyperLinkRichText,
  Component: FC<AnchorHTMLAttributes<any>>
) => {
  if (!text?.spans) return;
  const start = text.spans[0].start;
  const end = text.spans[0].end;
  const length = text.text.length;
  const Return: ReactNode = (
    <>
      {" "}
      {text.text.slice(0, start)}
      <Component
        href={text.spans[0].data.url}
        target={text.spans[0].data.target}
      >
        {text.text.slice(start, end + 1)}
      </Component>
      {text.text.slice(end + 1, length)}
    </>
  );
  return Return;
};
