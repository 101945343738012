import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Form } from './Form';
import { Head } from './Head';
import { CircularPicture } from '../../components/CircularPicture';
import { Hero, HeroImage } from '../../components/Hero';
import { Footer } from '../../components/Footer';

import contactImage from './contact.png';
import contactImageSuccess from './contactSuccess.jpg';
import hero from './hero.jpg';
// @ts-ignore
import heroPlaceholder from './heroPlaceholder.jpg?inline';

interface ContactProps {
}

const ContactContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding-top: calc(-1.875vw + 36px); /* 1920: 0, 320: 30 */
  padding-bottom: 200px;
  padding-left: calc(11.25vw + -6px); /* 1920: 210, 320: 30 */
  padding-right: calc(11.25vw + -6px); /* 1920: 210, 320: 30 */
  display: flex;
  width: 100%;
`;

const P1 = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: calc(0.8125vw + 24.4px); /* 1920: 40, 320: 27 */
  text-align: center;

  color: #FFFFFF;
`;

const P2 = styled.p`
  font-family: League Spartan;
  font-weight: 700;
  font-size: calc(0.25vw + 20.2px); /* 1920: 25, 320: 21 */
  text-align: center;

  color: #FD5D27;
  margin: 1em 0 2em 0
`;

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
`;

const PictureSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  @media (max-width: 840px) {
    display: none;
  }
`;
const PictureContainer = styled.div`
  position: relative;
  width: 100%;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;
const Picture = styled(CircularPicture)<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 0.5s;
`;

function Contact(_: ContactProps) {
  const subtitleText = 'Si quieres info o simplemente mandarnos un abrazo... contáctanos';
  const responseText = 'te responderemos lo antes posible. Mientras, piensa en 3 cosas que no dirías en una playa...';

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [p2Text, setP2Text] = useState(subtitleText);

  useEffect(() => {
    if (submitSuccess) {
      const getDelay = (min: number, jitter = min) => min + Math.random() * jitter;

      let erasing = true;
      const addChar = () => setP2Text(current => {
        const next = erasing
          ? ''
          : responseText.slice(0, current.length + 1);
        if (erasing) {
          erasing = false;
        }
        if (next.length < responseText.length) {
          setTimeout(addChar, getDelay(erasing ? 10 : 75));
        }
        return next;
      });

      addChar();
    }
  }, [submitSuccess]);

  return (
    <ContactContainer>
      <Head />
      <Hero title='Und... Klar! Estamos en Berlín'>
        <HeroImage
          src={hero}
          alt='Oberbaumbrücke'
          opacity={0.6}
          placeholder={heroPlaceholder}
        />
      </Hero>
      <Content>
        <FormSection>
          <P1>
            {submitSuccess
              ? '¡Gracias!'
              : 'Contáctanos'
            }
          </P1>
          <P2>
            {p2Text}
          </P2>
          <Form onSuccess={() => setSubmitSuccess(true)} />
        </FormSection>
        <PictureSection>
          <PictureContainer>
            <Picture
              src={contactImage}
              alt={'Paula y Ale al teléfono en una escena'}
              show={!submitSuccess}
            />
            <Picture
              src={contactImageSuccess}
              alt={'Paula y Eloi hablando en una escena'}
              show={submitSuccess}
            />
          </PictureContainer>
        </PictureSection>
        <Footer />
      </Content>
    </ContactContainer>
  );
}

export { Contact };
