import { Client, createClient } from "@prismicio/client";
import fetch from "node-fetch";

export class PrismicManager {
  client?: Client;
  prismicEndpoint?: string = process.env.REACT_APP_PRISMIC_ENDPOINT;
  prismicAccessToken?: string = process.env.REACT_APP_PRISMIC_ACCESS_TOKEN;
  repeatableDocumentTypes: string[] = [
    "training",
    "testimony",
    "facilitators",
    "event_service",
  ];
  singleDocumentTypes: string[] = ["enterprise_page"];
  data: Record<string, unknown> = {};

  constructor(private lang: string) {
    this.init();
  }

  init() {
    if (!this.prismicEndpoint) return;
    this.client = createClient(this.prismicEndpoint, {
      fetch,
      accessToken: this.prismicAccessToken,
    });
  }

  getData() {
    const repeatablePromises = this.repeatableDocumentTypes.map((type) => {
      return this.client
        ?.getByType(type, { lang: this.lang })
        .then((response) => {
          this.data[type] = response.results.map((result) => ({
            id: result.uid,
            ...result.data,
          }));
        });
    });
    const singlePromises = this.singleDocumentTypes.map((type) => {
      return this.client
        ?.getSingle(type, { lang: this.lang })
        .then((response) => {
          this.data[type] = response.data;
        });
    });
    return Promise.all([...repeatablePromises, ...singlePromises]);
  }
}
