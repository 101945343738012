import styled from 'styled-components';

interface InstagramProps {
}

const InstagramContainer = styled.svg`
  width: 26px;
  height: 26px;
  -webkit-tap-highlight-color: transparent;
`;

function Instagram(props: InstagramProps) {
  return (
    <InstagramContainer {...props} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M15.4542 13.6582C15.4542 15.0077 14.3603 16.1016 13.0109 16.1016C11.6614 16.1016 10.5675 15.0077 10.5675 13.6582C10.5675 12.3087 11.6614 11.2148 13.0109 11.2148C14.3603 11.2148 15.4542 12.3087 15.4542 13.6582Z' fill='currentColor' />
      <path d='M18.7251 9.33516C18.6077 9.01689 18.4203 8.7288 18.1768 8.49234C17.9403 8.24886 17.6525 8.06148 17.334 7.94402C17.0757 7.84371 16.6877 7.72431 15.973 7.69178C15.1999 7.65652 14.9682 7.64893 13.011 7.64893C11.0536 7.64893 10.8218 7.65633 10.0489 7.69158C9.33425 7.72431 8.94604 7.84371 8.68796 7.94402C8.36949 8.06148 8.0814 8.24886 7.84513 8.49234C7.60165 8.7288 7.41427 9.01669 7.29662 9.33516C7.19631 9.59344 7.07691 9.98165 7.04438 10.6963C7.00912 11.4692 7.00153 11.701 7.00153 13.6584C7.00153 15.6156 7.00912 15.8473 7.04438 16.6204C7.07691 17.3351 7.19631 17.7231 7.29662 17.9814C7.41427 18.2999 7.60146 18.5877 7.84494 18.8242C8.0814 19.0677 8.36929 19.2551 8.68776 19.3725C8.94604 19.473 9.33425 19.5924 10.0489 19.625C10.8218 19.6602 11.0534 19.6676 13.0108 19.6676C14.9683 19.6676 15.2001 19.6602 15.9728 19.625C16.6875 19.5924 17.0757 19.473 17.334 19.3725C17.9733 19.1259 18.4785 18.6207 18.7251 17.9814C18.8254 17.7231 18.9448 17.3351 18.9776 16.6204C19.0128 15.8473 19.0202 15.6156 19.0202 13.6584C19.0202 11.701 19.0128 11.4692 18.9776 10.6963C18.945 9.98165 18.8256 9.59344 18.7251 9.33516ZM13.011 17.4222C10.9321 17.4222 9.24679 15.7371 9.24679 13.6582C9.24679 11.5793 10.9321 9.89419 13.011 9.89419C15.0897 9.89419 16.775 11.5793 16.775 13.6582C16.775 15.7371 15.0897 17.4222 13.011 17.4222ZM16.9238 10.625C16.438 10.625 16.0441 10.2312 16.0441 9.74538C16.0441 9.25959 16.438 8.86574 16.9238 8.86574C17.4096 8.86574 17.8034 9.25959 17.8034 9.74538C17.8032 10.2312 17.4096 10.625 16.9238 10.625Z' fill='currentColor' />
      <path d='M13.011 0.893066C5.96202 0.893066 0.245728 6.60936 0.245728 13.6584C0.245728 20.7074 5.96202 26.4237 13.011 26.4237C20.06 26.4237 25.7763 20.7074 25.7763 13.6584C25.7763 6.60936 20.06 0.893066 13.011 0.893066ZM20.2969 16.6804C20.2614 17.4607 20.1374 17.9935 19.9562 18.4598C19.5754 19.4444 18.7971 20.2227 17.8124 20.6035C17.3463 20.7847 16.8134 20.9086 16.0333 20.9442C15.2516 20.9799 15.0019 20.9884 13.0112 20.9884C11.0203 20.9884 10.7708 20.9799 9.98897 20.9442C9.20886 20.9086 8.67593 20.7847 8.20982 20.6035C7.72052 20.4195 7.27759 20.131 6.9114 19.758C6.53858 19.392 6.25011 18.9489 6.06604 18.4598C5.88489 17.9936 5.76081 17.4607 5.72536 16.6806C5.68933 15.8988 5.68095 15.649 5.68095 13.6584C5.68095 11.6677 5.68933 11.418 5.72517 10.6363C5.76062 9.85601 5.8845 9.32327 6.06565 8.85696C6.24972 8.36786 6.53839 7.92473 6.9114 7.55873C7.27739 7.18573 7.72052 6.89725 8.20962 6.71318C8.67593 6.53203 9.20867 6.40815 9.98897 6.37251C10.7706 6.33686 11.0203 6.32829 13.011 6.32829C15.0017 6.32829 15.2514 6.33686 16.0331 6.3727C16.8134 6.40815 17.3461 6.53203 17.8124 6.71299C18.3015 6.89706 18.7447 7.18573 19.1108 7.55873C19.4837 7.92493 19.7723 8.36786 19.9562 8.85696C20.1375 9.32327 20.2614 9.85601 20.2971 10.6363C20.3327 11.418 20.3411 11.6677 20.3411 13.6584C20.3411 15.649 20.3327 15.8988 20.2969 16.6804Z' fill='currentColor' />
    </InstagramContainer>
  );
}

export { Instagram };
