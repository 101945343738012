import { FC, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { CollapsibleButton, CollapsibleButtonProps } from './CollapsibleButton';
import { clamp } from './utils';
import { useLayoutScroll } from '../hooks';

interface ScrollButtonProps extends Omit<CollapsibleButtonProps, 'open' | 'ref'> {
  from: number;
  to: number;
}

const ScrollButton: FC<ScrollButtonProps> = props => {
  const scroll = useLayoutScroll();
  const buttonRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(0);

  const rect = useMemo(() => (
    buttonRef.current && buttonRef.current.getBoundingClientRect()
  ), [buttonRef.current]);

  useEffect(() => {
    if (open < 1 && rect) {
      const scrollHeight = scroll.bottom - scroll.top;
      const scrollRange = scrollHeight * props.from - scrollHeight * props.to;

      const buttonScrollPosition = scroll.bottom - rect.top;
      const relativeScrollPosition = buttonScrollPosition - (scrollHeight - scrollHeight * props.from);

      const next = clamp(0, 1, relativeScrollPosition / scrollRange);
      if (next !== open) setOpen(scroll.end ? 1 : next);
    }
  }, [props, rect, scroll]);

  return <CollapsibleButton {...props} ref={buttonRef} open={open} />;
};

export { ScrollButton };
