import { FC } from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom'
import { Context, Environment } from '../types';

interface RouterProps {
  environment: Environment;
  context?: Context['router'];
}

function getProvider(environment: Environment) {
  let provider = BrowserRouter;
  if (environment === Environment.Static) {
    provider = StaticRouter;
  }
  return provider;
}
const getProviderProps = (environment: Environment, context?: Context['router']) => ({
    [Environment.Browser]: {},
    [Environment.Static]: { location: context?.path || '/' },
}[environment]);

const Router: FC<RouterProps> = ({ environment, context, children }) => {
  const Provider = getProvider(environment);
  const providerProps = getProviderProps(environment, context);

  return (
    <Provider {...providerProps}>
      {children}
    </Provider>
  );
};

export { Router };
