import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";

interface SectionProps {
  title: string;
  subtitle?: string;
}

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: calc(2.5vw + 40px); /* 992: 80, 320: 40 */
  overflow-y: visible;
  overflow:hidden;
  width: clamp(75%, calc(90% + 0.41666667 * (1200px - 100vw)), 90%);

  @media (max-width: 1199px) and (min-width: 320px) {
    width: calc(90% - 0.0833 * (100vw - 320px));
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const SectionTitle = styled.h2`
  color: ${colors.mainText};
  ${typographies.sectionTitle};

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.sectionTitleLittle};
  }
`;

const SectionSubtitle = styled.p`
  color: ${colors.mainComponent};
  ${typographies.sectionSubtitleLittle};
  margin-top:14px;
`;

export const Section: FC<SectionProps> = ({ title, subtitle, children }) => {
  return (
    <Container>
      <Header>
        <SectionTitle>{title}</SectionTitle>
        <SectionSubtitle>{subtitle}</SectionSubtitle>
      </Header>
      {children}
    </Container>
  );
};
