import { Helmet } from 'react-helmet-async';

function Head() {
  return (
    <Helmet>
      <title>Clases y workshops | ESIMPRO</title>
      <meta name="description" content="Aprende lo que sea en ESIMPRO Escuela: impro, teatro, expresión corporal, voz, trabajo en equipo. Español & English." />
    </Helmet>
  );
}

export default Head;
