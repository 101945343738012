import { useServices } from "../../../hooks";
import { FC, useMemo } from "react";
import styled from "styled-components";
import { typographies } from "../../../styles/typographies";
import { colors } from "../../../styles/variables";

const HeroContainer = styled.section`
  display: flex;
  align-items:center;
  gap: 7%;
  margin-bottom: 40px;
  width: clamp(75%, calc(90% + 0.41666667 * (1200px - 100vw)), 90%);

  @media (max-width: 1199px) and (min-width: 320px) {
    width: calc(90% - 0.0833 * (100vw - 320px));
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
  
  @media (min-width: 320px) and (max-width: 992px) {
    gap: 3.5%;
  }
`;

const Image = styled.img`
  border-radius: 22px;
  min-width: calc(93% / 2);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
  position: relative;
  top: -2px;
  left: -2px;
`;

const TextContainer = styled.div`
  color: ${colors.secondaryText};
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 320px) and (max-width: 992px) {
    padding-bottom: 24px;
`;

const Title = styled.h2`
  ${typographies.sectionTitle}

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.sectionTitleLittle};
  }
`;

const Description = styled.p`
  ${typographies.commonText};
  
  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.commonTextLittle};
  }
`;

export const Hero: FC = () => {
  const { data } = useServices();

  const imageURL = useMemo(() => {
    return data.enterprise_page?.intro[0].image.url;
  }, [data]);

  const imageAlt = useMemo(() => {
    return data.enterprise_page?.intro[0].image.alt;
  }, [data]);

  const title = useMemo(() => {
    return data.enterprise_page?.intro[0].title;
  }, [data]);

  const description = useMemo(() => {
    return data.enterprise_page?.intro[0].description;
  }, [data]);

  return (
    <HeroContainer>
      {imageURL && <Image src={imageURL} alt={imageAlt} />}
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
    </HeroContainer>
  );
};
