import { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ScrollButton } from '../../components/ScrollButton';
import { Content as CollapsibleButtonContent } from '../../components/CollapsibleButton';
import { LayoutContext } from '../../providers/Layout';

interface ContactUsProps {
}

const ContactUsContainer = styled.div`
  margin: calc(2.1875vw + 23px) 0 100px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
`;

const ContactButton = styled(ScrollButton)`
  font-size: 18px;
  color: white;
  font-family: League Spartan;
  ${CollapsibleButtonContent} {
    transform: translate(-2px, -2px);
  };
`;

function ContactUs(_: ContactUsProps) {
  const { scrollUp } = useContext(LayoutContext);

  return (
    <ContactUsContainer>
      <Link to='/contact.html?category=school'>
        <ContactButton
          borderColor='#FD5D27'
          fillColor='#FD5D27'
          from={0.9}
          to={0.75}
          onClick={scrollUp}
        >
          contacto
        </ContactButton>
      </Link>
    </ContactUsContainer>
  );
}

export default ContactUs;
