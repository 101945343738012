import styled from 'styled-components';

interface ArrowProps {
  onClick?(): void;
}

const ArrowContainer = styled.svg`
  width: 75px;
  -webkit-tap-highlight-color: transparent;
`;

function Arrow(props: ArrowProps) {
  const stroke = 6;
  const length = 75;
  const height = 50;
  const halfStroke = stroke / 2;

  return (
    <ArrowContainer {...props} viewBox={`0 0 ${length} ${height}`} xmlns="http://www.w3.org/2000/svg">
      <circle cx={halfStroke} cy={height / 2} r={halfStroke} fill="currentColor" />
      <circle cx={length / 2 - 21} cy={height / 2} r={halfStroke} fill="currentColor" />
      <line x1={length - height / 2 - halfStroke} y1={0 + halfStroke} x2={length - halfStroke - 2} y2={height / 2 - 2} stroke="currentColor" strokeWidth={stroke} strokeLinecap="round" />
      <line x1={length / 2 - 10} y1={height / 2} x2={length - halfStroke - 1} y2={height / 2} stroke="currentColor" strokeWidth={stroke} strokeLinecap="round" />
      <line x1={length - height / 2 - halfStroke} y1={height - halfStroke} x2={length - halfStroke - 2} y2={height / 2 + 2} stroke="currentColor" strokeWidth={stroke} strokeLinecap="round" />
    </ArrowContainer>
  );
}

export { Arrow };
