import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { usePersistedState } from './usePersistedState';
import { useNewsletterModal } from './useNewsletterModal';
import { useServices } from './useServices';
import { WishlistTypes } from '../types';

const apiUrl = process.env.REACT_APP_API_URL;

function useWishlist(wishlistType: WishlistTypes, id: string) {
  const { fingerprint } = useServices();
  const [filled, setFilled] = usePersistedState(false, `wishlist.${wishlistType}.${id}`);
  const [newsletterModalProps, newsletterModalOpen] = useNewsletterModal(`wishlist|${wishlistType}|${id}`);

  const onClick = () => setFilled(current => {
    const filled = !current;

    if (filled) newsletterModalOpen();

    fetch(`${apiUrl}/wishlist`, {
      method: filled ? 'PUT' : 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        type: wishlistType,
        vote: id,
        fingerprint,
      })
    });

    return filled;
  });

  const heartProps = useMemo(() => ({ filled, onClick }), [filled, onClick]);

  return {
    heartProps,
    newsletterModalProps,
  };
}

export { useWishlist };
