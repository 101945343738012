import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";

export interface PageTitleProps {
  title: string;
}

const Title = styled.h1`
  color: ${colors.mainText};
  height: 30%;
  position: relative;
  top: 60%;
  width: 60%;
  ${typographies.pageTitle}

  @media (min-width: 320px) and (max-width: 992px) {
    ${typographies.pageTitleLittle};
  }
`;

export const PageTitle: FC<PageTitleProps> = ({ title }) => {
  return <Title>{title}</Title>;
};
