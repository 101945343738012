import styled from 'styled-components';
import { CircularPicture } from '../../components/CircularPicture';

interface PersonProps {
  picture: string;
  name: string;
}

const PersonContainer = styled.div`
`;

const Avatar = styled(CircularPicture)`
  width: calc(4.125vw + 77.8px); /* 1920: 157, 320: 91 */
  height: calc(4.125vw + 77.8px); /* 1920: 157, 320: 91 */
`;

const Name = styled.p`
  font-family: League Spartan;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
`;

const FirstName = styled(Name)`
  margin-top: 1em;
`;

const FamilyName = styled(Name)`
  margin-top: 0.25em;
`;

function Person(props: PersonProps) {
  const [firstName, familyName] = props.name.split(' ');
  return (
    <PersonContainer>
      <Avatar src={props.picture} alt={props.name} />
      <FirstName>{firstName}</FirstName>
      <FamilyName>{familyName}</FamilyName>
    </PersonContainer>
  );
}

export { Person };
