import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";

type ButtonColor = "primary" | "secondary";

interface CommonButton {
  color?: ButtonColor;
}

export const CommonButton = styled.button<CommonButton>`
  background: ${({ color = "primary" }) =>
    color === "primary" ? colors.mainComponent : colors.tertiaryComponent};
  border-radius: 100px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(253, 93, 39, 0.07);
  padding: 14px 24px;
  transition: background 0.5s;
  ${typographies.button}

  &:disabled {
    background-color: grey;
    cursor: default;
  }
`;
