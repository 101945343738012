export function lerp(
  current: number,
  goal: number,
  fraction: number,
  uncertainty: number = 0
) {
  const min = Math.min(current, goal);
  const max = Math.max(current, goal);
  const result = (goal - current) * fraction + current;

  return Math.abs(min - result) < uncertainty
    ? goal
    : Math.abs(max - result) < uncertainty
    ? goal
    : result;
}

export function clamp(min: number, max: number, current: number) {
  if (current < min) return min;
  if (current > max) return max;
  return current;
}
