import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Head from './Head';
import { PromoSchool } from './PromoSchool';
import { PromoTeams } from './PromoTeams';
import { Hero, HeroImage } from '../../components/Hero';
import { Footer } from '../../components/Footer';

import hero from './hero.jpg';
// @ts-ignore
import heroPlaceholder from './heroPlaceholder.jpg?inline';

interface HomeProps {
}

const HomeContainer = styled.div`
`;

const Content = styled.div`
  position: relative;
  top: 100vh;
  padding: calc(-1.875vw + 36px); /* 1920: 0, 320: 30 */
  padding-bottom: 200px;
`;

const Title = styled.div`
  color: #FFFFFF;
  font-family: League Spartan;
  font-style: normal;
  font-weight: bold;
  font-size: calc(2.435897435897436vw + 15.23076923076923px);
  line-height: 1.25em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  width: 11em;
  margin-bottom: 0.5em;
`;

const Subtitle = styled.div`
  color: #FFFFFF;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 500;
  font-size: calc(0.725vw + 10px); /* 1920: 25, 320: 13.4 */
  line-height: 1.25em;
  text-align: center;

  width: 18em;
`;

const HeroHome = styled(Hero)`
  padding-top: calc(-6.25vw + 120px); /* 1920: 0, 320: 100 */
`;

const ShowsButton = styled.div`
  font-size: 18px;
  color: #9BFFED;
  font-family: League Spartan;
  margin-top: 50px;
  border-radius: 30px;
  border: 1px solid #9BFFED;
  padding: 0.6em 1.6em;
`;

function Home(_: HomeProps) {
  return (
    <HomeContainer>
      <Head />
      <HeroHome title=''>
        <HeroImage
          src={hero}
          alt='Público en un show en directo'
          placeholder={heroPlaceholder}
        />
        <Title>
          Ríete, emociónate, improvisa
        </Title>
        <Subtitle>
          Ya sea siendo testigo o aprendiendo a improvisar, siempre puedes aplicarlo a tu vida
        </Subtitle>
        <Link to='/shows.html'>
          <ShowsButton
          >
            shows
          </ShowsButton>
        </Link>
      </HeroHome>
      <Content>
        <PromoSchool />
        <PromoTeams />
        <Footer />
      </Content>
    </HomeContainer>
  );
}

export { Home };
