import styled from 'styled-components';

interface HamburgerMenuIconProps {
  open?: boolean;
  inSchoolPage?: boolean;
  onClick(): void;
}

const HamburgerMenuIconContainer = styled.svg<{ inSchoolPage?: boolean }>`
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: ${({ inSchoolPage }) => inSchoolPage
      ? '#707070'
      : '#E8E8E8'
    };
  }
  -webkit-tap-highlight-color: transparent;
`;

const Line1 = styled.line<{ open?: boolean }>`
  stroke: currentColor;
  stroke-width: 4px;
  stroke-linecap: round;
  transition: transform 0.2s;

  transform-origin: 33px 40px;
  transform: ${({ open }) => open
    ? 'translateX(6px) rotate(45deg) scaleX(0.87)'
    : 'rotate(0deg)'
  };
`;

const Line2 = styled.line<{ open?: boolean }>`
  stroke: currentColor;
  stroke-width: 4px;
  stroke-linecap: round;
  transition: transform 0.2s;

  transform-origin: 50px 50px;
  transform: ${({ open }) => open
    ? 'scale(0)'
    : 'scale(1)'
  };
`;

const Line3 = styled.line<{ open?: boolean }>`
  stroke: currentColor;
  stroke-width: 4px;
  stroke-linecap: round;
  transition: transform 0.2s;

  transform-origin: 33px 60px;
  transform: ${({ open }) => open
    ? 'translateX(6px) rotate(-45deg) scaleX(0.87)'
    : 'rotate(0deg)'
  };
`;

function HamburgerMenuIcon({ open, ...props }: HamburgerMenuIconProps) {
  return (
    <HamburgerMenuIconContainer viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Line1 x1="33" y1="40" x2="66" y2="40" open={open} />
      <Line2 x1="33" y1="50" x2="66" y2="50" open={open} />
      <Line3 x1="33" y1="60" x2="66" y2="60" open={open} />
    </HamburgerMenuIconContainer>
  );
}

export { HamburgerMenuIcon };
