import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { FormField } from "./FormField";
import { Color as ButtonColor } from "../../../components/Button";
import { SubmitButton } from "../../../components/Form";

interface FormProps {
  onSuccess?(): void;
}

const apiUrl = process.env.REACT_APP_API_URL;
export const privacyPolicy =
  "https://www.privacypolicies.com/live/941ff16c-7706-4122-a04a-37cc4a8ab134";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width: 630px) {
    width: 100%;
  }
`;

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 1em;

  @media (max-width: 630px) {
    flex-direction: column;
  }
`;

export const Input = styled.input`
  color: #dddddd;
  width: 100%;
  font-size: 17px;
`;
export const TextArea = styled.textarea`
  color: #dddddd;
  width: 100%;
  min-height: 4em;
  font-size: 15px;
`;

interface CheckboxProps {
  checked?: boolean;
}
export const Checkbox = styled.div<CheckboxProps>`
  flex: 0 0 19px;
  height: 19px;
  border: 1px solid rgba(155, 255, 237, 0.37);
  box-sizing: border-box;
  border-radius: 50%;

  position: relative;
  &::after {
    position: absolute;
    top: 2px;
    left: 2px;
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #9bffed;
    transform: scale(${({ checked }) => (checked ? 1 : 0)});
    transition: transform 0.1s;
  }
`;

export const CheckboxLabel = styled.div`
  font-family: Quicksand;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.25em;

  align-items: center;

  color: #ffffff;

  margin-left: 1em;
`;

export const Link = styled.a`
  color: white;
  font-weight: 700;
  margin-left: 0.25em;
`;

export const FamilyNameField = styled(FormField)`
  flex: 1.25;

  @media (max-width: 630px) {
    margin-top: 16px;
  }
`;

const NewsletterRow = styled(FormRow)`
  cursor: pointer;
  @media (max-width: 630px) {
    flex-direction: row;
  }
`;
export const PrivacyRow = styled(NewsletterRow)`
  margin-bottom: 3em;
`;

export const CategoryRow = styled(FormRow)<{ open: boolean }>`
  display: block;
  background-color: ${({ open }) => (open ? "#FD5D27" : "")};
  border: 1px solid #9bffed;
  border-radius: 20px/20px;
  margin-right: 4px;
  align-items: flex-start;
  padding: 0.75em 1em;
  position: relative;
  flex-direction: column;
  transform-origin: top;
  transition: background-color 0.1s;
`;

export const Category = styled.div<{ noMargin?: boolean }>`
  font-weight: 700;
  ${({ noMargin }) => (!noMargin ? "margin: 0.3em 0;" : "")}

  &:hover {
    cursor: pointer;
  }
`;

const categoryNames = {
  school: "Escuela",
  shows: "Shows y eventos",
  teams: "ESIMPRO Teams",
  other: "Otras",
};

const useQuery = () => new URLSearchParams(useLocation().search);
const parseCategory = (input: string): keyof typeof categoryNames | null =>
  Object.keys(categoryNames).includes(input)
    ? (input as keyof typeof categoryNames)
    : null;

function Form(props: FormProps) {
  const query = useQuery();
  const initCategory = query.has("category")
    ? parseCategory(query.get("category")!)
    : null;

  const [name, setName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState<null | keyof typeof categoryNames>(
    initCategory
  );
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [privacy, setPrivacy] = useState(false);

  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  useEffect(() => {
    if (!email || !category || !message || !privacy) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [email, category, message, privacy]);

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const ref0 = useRef<HTMLDivElement>(null);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);
  const ref4 = useRef<HTMLDivElement>(null);
  const ref5 = useRef<HTMLDivElement>(null);
  const rects = useRef(new Map()).current;

  useLayoutEffect(() => {
    const cached1 = rects.get(ref1);
    if (cached1 && ref1.current) {
      const next = ref1.current.getBoundingClientRect();
      const translateY = cached1.y - next.y;
      rects.set(ref1, next);
      ref1.current.animate(
        [
          { transform: `translateY(${translateY}px)` },
          { transform: `translateY(0px)` },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
    }
    const cached2 = rects.get(ref2);
    if (cached2 && ref2.current) {
      const next = ref2.current.getBoundingClientRect();
      const translateY = cached2.y - next.y;
      rects.set(ref2, next);
      ref2.current.animate(
        [
          { transform: `translateY(${translateY}px)` },
          { transform: `translateY(0px)` },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
    }
    const cached3 = rects.get(ref3);
    if (cached3 && ref3.current) {
      const next = ref3.current.getBoundingClientRect();
      const translateY = cached3.y - next.y;
      rects.set(ref3, next);
      ref3.current.animate(
        [
          { transform: `translateY(${translateY}px)` },
          { transform: `translateY(0px)` },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
    }
    const cached4 = rects.get(ref4);
    if (cached4 && ref4.current) {
      const next = ref4.current.getBoundingClientRect();
      const translateY = cached4.y - next.y;
      rects.set(ref4, next);
      ref4.current.animate(
        [
          { transform: `translateY(${translateY}px)` },
          { transform: `translateY(0px)` },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
    }
    const cached5 = rects.get(ref5);
    if (cached5 && ref5.current) {
      const next = ref5.current.getBoundingClientRect();
      const translateY = cached5.y - next.y;
      rects.set(ref5, next);
      ref5.current.animate(
        [
          { transform: `translateY(${translateY}px)` },
          { transform: `translateY(0px)` },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
    }

    const cached0 = rects.get(ref0);
    if (cached0 && ref0.current) {
      const next = ref0.current.getBoundingClientRect();
      const scaleY = cached0.height / next.height;
      rects.set(ref0, next);
      ref0.current.animate(
        [
          {
            transform: `scaleY(${scaleY})`,
            borderRadius: `20px/${20 / scaleY}px`,
          },
          {
            transform: "scaleY(1)",
            borderRadius: "20px/20px",
          },
        ],
        {
          duration: 300,
          easing: "ease",
        }
      );
      (ref0.current.childNodes[0] as HTMLDivElement).animate(
        [
          {
            opacity: "0",
          },
          {
            opacity: "1",
          },
        ],
        {
          duration: 450,
          easing: "ease",
        }
      );
    }
  }, [categoriesOpen]);

  const openCategories = () => {
    rects.set(ref0, ref0.current!.getBoundingClientRect());
    rects.set(ref1, ref1.current!.getBoundingClientRect());
    rects.set(ref2, ref2.current!.getBoundingClientRect());
    rects.set(ref3, ref3.current!.getBoundingClientRect());
    rects.set(ref4, ref4.current!.getBoundingClientRect());
    rects.set(ref5, ref5.current!.getBoundingClientRect());

    setCategoriesOpen(true);
  };
  const closeCategories = () => {
    rects.set(ref0, ref0.current!.getBoundingClientRect());
    rects.set(ref1, ref1.current!.getBoundingClientRect());
    rects.set(ref2, ref2.current!.getBoundingClientRect());
    rects.set(ref3, ref3.current!.getBoundingClientRect());
    rects.set(ref4, ref4.current!.getBoundingClientRect());
    rects.set(ref5, ref5.current!.getBoundingClientRect());

    setCategoriesOpen(false);
  };

  const selectCategory = (category: keyof typeof categoryNames) => () => {
    setCategory(category);
    closeCategories();
  };

  return (
    <FormContainer>
      {!submitSuccess && (
        <>
          <FormRow>
            <FormField label="Nombre" value={name}>
              <Input
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </FormField>
            <FamilyNameField label="Apellidos" value={familyName}>
              <Input
                type="text"
                value={familyName}
                onChange={(event) => setFamilyName(event.target.value)}
              />
            </FamilyNameField>
          </FormRow>
          <FormRow>
            <FormField label="Correo electrónico" value={email}>
              <Input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormField>
          </FormRow>
          <CategoryRow ref={ref0} open={categoriesOpen}>
            <div style={{ transformOrigin: "top" }}>
              {categoriesOpen ? (
                <>
                  <Category onClick={selectCategory("school")}>
                    {categoryNames.school}
                  </Category>
                  <Category onClick={selectCategory("shows")}>
                    {categoryNames.shows}
                  </Category>
                  <Category onClick={selectCategory("teams")}>
                    {categoryNames.teams}
                  </Category>
                  <Category onClick={selectCategory("other")}>
                    {categoryNames.other}
                  </Category>
                </>
              ) : (
                <Category onClick={openCategories} noMargin>
                  {category ? categoryNames[category] : "Categoría"}
                </Category>
              )}
            </div>
          </CategoryRow>
          <FormRow ref={ref1}>
            <FormField label="Asunto" value={subject}>
              <Input
                type="text"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
              />
            </FormField>
          </FormRow>
          <FormRow style={{ marginBottom: "1em" }} ref={ref2}>
            <FormField label="Mensaje" value={message} textArea>
              <TextArea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </FormField>
          </FormRow>
          <NewsletterRow onClick={() => setNewsletter((x) => !x)} ref={ref3}>
            <Checkbox checked={newsletter} />
            <CheckboxLabel>
              Suscribirme a la ñusletter. ¡Lo quiero saber todo!
            </CheckboxLabel>
          </NewsletterRow>
          <PrivacyRow onClick={() => setPrivacy((x) => !x)} ref={ref4}>
            <Checkbox checked={privacy} />
            <CheckboxLabel>
              He leído y acepto la
              <Link href={privacyPolicy} target="_blank">
                Política de Privacidad
              </Link>
            </CheckboxLabel>
          </PrivacyRow>
        </>
      )}
      <SubmitButton
        ref={ref5}
        disabled={submitDisabled}
        submitting={submitting}
        success={submitSuccess}
        borderColor="#FD5D27"
        fillColor="#FD5D27"
        from={0.9}
        to={0.7}
        onClick={() => {
          setSubmitDisabled(true);
          setSubmitting(true);

          Promise.all([
            // Artifically delay the response to display the animation
            new Promise((resolve) => setTimeout(resolve, 2000)),
            fetch(`${apiUrl}/sendContactMessage`, {
              method: "POST",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                name,
                familyName,
                email,
                category,
                subject,
                message,
                newsletter,
                privacy,
              }),
            }),
          ]).then((success) => {
            setSubmitting(false);
            setSubmitSuccess(true);
            if (typeof props.onSuccess === "function") {
              props.onSuccess();
            }
          });
        }}
      >
        enviar
      </SubmitButton>
    </FormContainer>
  );
}

export { Form };
