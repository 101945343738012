import { FC, useMemo } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { typographies } from "../../../styles/typographies";
import { TestimonialStarSvg } from "../assets/TestimonialStar.svg";
import {
  getMobileMediaQuery,
  getTabletMediaQuery,
} from "../../../utils/mediaQuery";

interface TestimonyCardProps {
  name: string;
  position: string;
  company: string;
  scoring: string;
  testimony: string;
}

const TestimonyContainer = styled.div`
  display: flex;
  gap: 7%;
  width: 100%;
  ${getMobileMediaQuery(`
    flex-direction: column-reverse;
    gap: 35px;
    width: 90%;
  `)}
`;

const LeftContainer = styled.div`
  color: ${colors.secondaryText};
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 160px;
  ${getTabletMediaQuery(`
    width: 235px;
  `)}
`;

const Name = styled.h3`
  white-space: nowrap;
  ${typographies.facilitatiorBackText}
`;

const Position = styled.p`
  ${typographies.commonTextLittle}
`;

const Company = styled.p`
  ${typographies.commonTextBoldLittle}
`;

const TestimonyText = styled.p`
  color: ${colors.secondaryText};
  ${typographies.testimony}
`;

const ScoringContainer = styled.div`
  color: ${colors.mainComponent};
  display: flex;
`;

export const TestimonyCard: FC<TestimonyCardProps> = ({
  name,
  position,
  company,
  scoring,
  testimony,
}) => {
  const floatScoring = useMemo(
    () => ({
      integer: Math.floor(parseInt(scoring) / 2),
      decimal: parseInt(scoring) / 2 - Math.floor(parseInt(scoring) / 2),
    }),
    [scoring]
  );

  return (
    <TestimonyContainer>
      <LeftContainer>
        <Name>{name},</Name>
        <Position>{position}</Position>
        <Company>{company}</Company>
        <ScoringContainer>
          {new Array(floatScoring.integer).fill(null).map((_, index) => (
            <TestimonialStarSvg key={index} />
          ))}
          {floatScoring.decimal ? <TestimonialStarSvg half={true} /> : null}
        </ScoringContainer>
      </LeftContainer>
      <TestimonyText>{testimony}</TestimonyText>
    </TestimonyContainer>
  );
};
