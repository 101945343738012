import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { cookieconsent } from '../lib/cookie-consent';
import { Environment } from '../types';

interface CookiesProps {
  environment: Environment;
}

const Cookies: FC<CookiesProps> = ({ children, environment }) => {
  useEffect(() => {
    if (environment === Environment.Static) return;

    // This timeout just in case:
    // To be sure, it would need to be tested to run this -before-
    // react-helmet has added the scripts to the page, and see what happens.
    setTimeout(() => {
      cookieconsent.run({
        notice_banner_type: 'simple',
        consent_type: 'express',
        palette: 'dark',
        language: 'es',
        website_name: 'ESIMPRO',
        cookies_policy_url: 'https://www.privacypolicies.com/live/622d5d94-b698-423c-83ea-7801da6fb183'
      });
    }, 1000);
  }, [cookieconsent, environment]);

  return (
    <>
      <Helmet>
        {/* Tracking services are managed by CookieConsent (https://www.CookieConsent.com), see src/providers/Cookies.tsx */}
        {/* Google Analytics */}
        <script type="text/plain" cookie-consent="tracking" async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`}></script>
        <script type="text/plain" cookie-consent="tracking">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS}');
            gtag('set', { 'cookie_flags': 'SameSite=None;Secure' });
          `}
        </script>
        {/* End of Google Analytics */}
      </Helmet>
      {children}
    </>
  );
};

export { Cookies };
