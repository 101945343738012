export const DownArrowSvg = () => {
  return (
    <svg
      width="26"
      height="15"
      viewBox="0 0 26 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.348969L12.0823 13.4579C12.4783 13.9263 13.1986 13.9312 13.6009 13.4682L25 0.348969"
        stroke="currentColor"
      />
    </svg>
  );
};
