import styled from 'styled-components';
import Workshop from './Workshop';
import { WorkshopTypes } from './types';
import { getWorkshopImage } from './utils';

interface WorkshopsProps {
}

const WorkshopsContainer = styled.div`
  margin-top: 80px;
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  @media (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1042px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 784px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 526px) {
    grid-template-columns: 1fr;
  }

  @media (min-width: 785px) {
    padding: 0 calc(15.418502202643172vw + -121.0352422907489px); /* 1920: 175, 785: 0 */
  }
`;

const workshops = [
  {
    id: WorkshopTypes.EscenasDeCine,
    title: 'Escenas de Cine',
    description: 'Descubre tu artista interior.',
    image: getWorkshopImage(WorkshopTypes.EscenasDeCine),
  },
  {
    id: WorkshopTypes.ImprovisateUnTema,
    title: 'Improvísate un tema',
    description: 'Aprende a crear canciones y letras improvisadas.',
    image: getWorkshopImage(WorkshopTypes.ImprovisateUnTema),
  },
  {
    id: WorkshopTypes.ReConecta,
    title: 'Re´conecta',
    description: 'Un taller donde el arte te reconectará',
    image: getWorkshopImage(WorkshopTypes.ReConecta),
  },
  {
    id: WorkshopTypes.Improcampa,
    title: 'ESimproCampa',
    description: 'Un retiro improvisado, un paraíso de la impro.',
    image: getWorkshopImage(WorkshopTypes.Improcampa),
  },
];


function Workshops(_: WorkshopsProps) {
  return (
    <WorkshopsContainer>
      {workshops.map(workshop => (
        <Workshop key={workshop.id} {...workshop} />
      ))}
    </WorkshopsContainer>
  );
}

export default Workshops;
