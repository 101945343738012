import { useLayoutEffect, useRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLayoutScroll } from '../../hooks';
import { easeOutCubic } from '../utils';

export interface HeroImageProps {
  src: string;
  alt: string;
  placeholder?: string;
  opacity?: number;
}

const HeroImageContainer = styled.div.attrs<{ opacity: number }>(
  ({ opacity }) => ({
    style: {
      opacity
    },
  })
)<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transform-origin: 0 0;
  transform: translateZ(-1px) scale(2);
  transition: opacity 0.2s;
  z-index: -1;

  width: 850px;
  @media (min-width: 1175px) {
    width: 50vw;
  }
`;

const AspectRatioBox = styled.div<{ ratio: number }>`
  width: 100%;
  height: 0;
  padding-top: ${({ ratio }) => ratio * 100}%;
  transform: translateX(min(0px, calc(47.5vw + -558.125px)));
`;

const Overlay = styled.div.attrs<{ size: number }>(
  ({ size }) => ({
    style: {
      transform: `scaleY(${size})`,
    },
  })
)<{ size: number }>`
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0,2,39,1) 0%, rgba(0,2,39,0) 100%);
  transform-origin: bottom;
`;

const ImageContainer = styled.div<{ opacity: number; transitionDuration: string }>`
  opacity: ${({ opacity }) => opacity};
  transition: opacity ${({ transitionDuration }) => transitionDuration}s ease-out;
  & > img {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-position: min(0px, calc(22.58064516129032vw + -142.25806451612902px));
  }
`;

const Placeholder = styled.img<{ opacity: number; transitionDuration: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ opacity }) => opacity};
  transition: opacity ${({ transitionDuration }) => transitionDuration}5s ease-in;
  filter: blur(4px);
`;

const aspectRatio = 845 / 1440;

function HeroImage(props: HeroImageProps) {
  const {
    src,
    alt,
    placeholder,
    opacity = 1,
    ...restProps
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLImageElement>(null);
  const image = useMemo(() => {
    if (typeof window !== 'undefined') {
      const image = new window.Image();
      image.onload = () => setShowImage(true);
      image.alt = alt;
      image.src = src;

      return image;
    }
  }, []);
  const [showImage, setShowImage] = useState(!placeholder || image?.complete);
  const [placeholderTransitionDuration, setPlaceholderTransitionDuration] = useState('0.0');
  const scroll = useLayoutScroll();

  const containerOpacity = Math.max(0, 1 - easeOutCubic((scroll.top / (containerRef.current?.clientHeight || 1000))));

  useLayoutEffect(() => {
    if (imageContainerRef.current && image) {
      imageContainerRef.current.appendChild(image);
    }
    setTimeout(() => setPlaceholderTransitionDuration('0.2'), 150);
  }, []);

  return (
    <HeroImageContainer {...restProps} ref={containerRef} opacity={containerOpacity}>
      <AspectRatioBox ratio={aspectRatio}>
        {placeholder && (
          <Placeholder
            src={placeholder}
            alt={alt}
            opacity={showImage ? 0 : opacity * 0.8}
            transitionDuration={placeholderTransitionDuration}
          />
        )}
        <ImageContainer
          ref={imageContainerRef}
          opacity={showImage ? opacity : 0}
          transitionDuration={placeholderTransitionDuration}
        />
        <Overlay size={scroll.top / 200} />
      </AspectRatioBox>
    </HeroImageContainer>
  );
}

export { HeroImage, AspectRatioBox };
